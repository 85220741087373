import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";

const ServicesSection = () => {
  const language = useSelector((state) => state.language);
  const activeLanguage = language.language || "en";
  const translations = language.translations || {};

  const servicesTranslations =
    translations[activeLanguage]?.servicesTranslations || {
      header: "Shërbimet dhe Ofertat",
      card1Title: "Menaxhimi i Ndërtimit dhe Projektit",
      card1Description: "Ne ofrojmë shërbime gjithëpërfshirëse të menaxhimit për të udhëhequr projektet e ndërtimit nga koncepti deri në përfundim.",
      card2Title: "Keramika dhe Bluarja e Gurit",
      card2Description: "Ne ofrojmë një gamë të gjerë produktesh dhe shërbimesh qeramike për të përmirësuar cilësinë e materialeve të ndërtimit.",
      card3Title: "Furnizime me Energjinë e Rinovueshme", 
      card3Description: "Ne specializohemi në teknologji inovative dhe materiale për të përmirësuar efikasitetin e energjisë dhe për të ulur kostot.",
    };

  const cardData = [
    {
      image: "/assets/betonCard.jpg",
      title: servicesTranslations.card1Title,
      description: servicesTranslations.card1Description,
    },
    {
      image: "/assets/11.jpg",
      title: servicesTranslations.card2Title,
      description: servicesTranslations.card2Description,
    },
    {
      image: "/assets/12.jpeg",
      title: servicesTranslations.card3Title,
      description: servicesTranslations.card3Description,
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); 
  }, []);

  return (
    <section className="mb-12 py-10 bg-gray-50 rounded-lg shadow-lg">
      <h2
        className="text-4xl md:text-5xl font-bold text-gray-800 text-center mb-8"
        data-aos="fade-up" 
      >
        {servicesTranslations.header} 
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mx-auto" style={{ width: '80%' }}>
        {cardData.map((data, index) => (
          <div
            key={index}
            className="bg-white text-center rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out flex flex-col justify-between p-6"
            data-aos="fade-up" 
            data-aos-delay={`${index * 200}`} 
          >
            <img 
              src={data.image} 
              alt={data.title} 
              className="w-full h-48 object-cover rounded-t-lg"
            />
            <div className="mt-4 flex-grow"> 
              <h3 className="text-xl font-semibold text-gray-800">{data.title}</h3> 
              <p className="text-gray-600 mt-2">{data.description}</p> 
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
