import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const TeamMembers = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); 
  }, []);

  return (
    <div className="pt-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
        <div
          className="flex flex-col items-center"
          data-aos="fade-up" 
        >
          <img
            src="assets/team.png"
            alt="Abedin Salihu"
            className="w-48 h-48 object-cover rounded-full shadow-lg mb-4"
          />
          <p className="text-lg font-medium text-gray-700">Abedin Salihu</p>
        </div>
        <div
          className="flex flex-col items-center"
          data-aos="fade-up"
          data-aos-delay="100" 
        >
          <img
            src="assets/team.png"
            alt="Ibrahim Salihu"
            className="w-48 h-48 object-cover rounded-full shadow-lg mb-4"
          />
          <p className="text-lg font-medium text-gray-700">Ibrahim Salihu</p>
        </div>
        <div
          className="flex flex-col items-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <img
            src="assets/team.png"
            alt="Sabedin Salihu"
            className="w-48 h-48 object-cover rounded-full shadow-lg mb-4"
          />
          <p className="text-lg font-medium text-gray-700">Sabedin Salihu</p>
        </div>
        <div
          className="flex flex-col items-center"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <img
            src="assets/team.png"
            alt="Ramadan Salihu"
            className="w-48 h-48 object-cover rounded-full shadow-lg mb-4"
          />
          <p className="text-lg font-medium text-gray-700">Ramadan Salihu</p>
        </div>
        <div
          className="flex flex-col items-center"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <img
            src="assets/team.png"
            alt="Nexhmedin Salihu"
            className="w-48 h-48 object-cover rounded-full shadow-lg mb-4"
          />
          <p className="text-lg font-medium text-gray-700">Nexhmedin Salihu</p>
        </div>
      </div>
    </div>
  );
};

export default TeamMembers;
