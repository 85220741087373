import React, { useEffect } from "react";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import Translations from "../redux/language/Translations";
import { Link } from "react-router-dom";

const useNewsData = (currentLanguage) => {
  const news = Translations[currentLanguage]?.news || [];
  return news.map((item, index) => ({
    id: index + 1,
    title: item.title,
    content: item.content,
    image: item.image,
  }));
};

const NewsAboutDino = () => {
  const language = useSelector((state) => state.language);
  const currentLanguage = language.language || "en";
  const newsData = useNewsData(currentLanguage);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true, 
    centerPadding: "0", 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "10px", 
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "0", 
        },
      },
    ],
  };

  const translations = {
    en: {
      sectionTitle: "Latest News",
      readMore: "Read More",
    },
    sq: {
      sectionTitle: "Lajmet E Fundit",
      readMore: "Më shumë",
    },
  };

  const sectionTitle =
    translations[currentLanguage]?.sectionTitle || translations.en.sectionTitle;
  const readMore =
    translations[currentLanguage]?.readMore || translations.en.readMore;

  return (
    <section className="w-full py-20 bg-gray-50 overflow-hidden" data-aos="fade-up">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12 relative inline-block" data-aos="fade-up">
          <h1 className="text-4xl md:text-6xl font-bold text-red-800 mb-8">
            {sectionTitle}
          </h1>
          <div
            className="absolute w-full h-1 bg-red-800"
            style={{
              width: "280%",
              left: "-10%",
            }}
            data-aos="slide-right"
          ></div>
        </div>

        {newsData.length > 0 ? (
          <Slider {...sliderSettings}>
            {newsData.map((news, index) => (
              <div
                key={news.id}
                className="relative bg-white rounded-lg overflow-hidden transform hover:scale-105 transition duration-300 mx-2"
                data-aos="fade-up"
                data-aos-delay={index * 200}
              >
                <div
                  className="w-full h-96 bg-cover bg-center"
                  style={{ backgroundImage: `url(${news.image})` }}
                ></div>

                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center p-6 text-center">
                  <h3 className="text-xl font-semibold text-white mb-2">
                    {news.title}
                  </h3>
                  <p className="text-white text-sm mb-6">
                    {news.content.length > 100
                      ? `${news.content.substring(0, 100)}...`
                      : news.content}
                  </p>
                  <Link
                    to={`/news/${news.id}`}
                    className="inline-block text-white py-2 px-4 rounded hover:bg-red-700 hover:text-white transition duration-200 no-underline text-center"
                  >
                    {readMore}
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p className="text-center text-gray-500">
            No news available at the moment.
          </p>
        )}
      </div>
    </section>
  );
};

export default NewsAboutDino;