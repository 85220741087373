import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ContactUs from "../components/ContactUs";
import Marquee from "../components/Marquee";
import AOS from "aos";
import "aos/dist/aos.css";

const DinoNdertimi = () => {
	const language = useSelector((state) => state.language);
	const activeLanguage = language.language || "en";
	const translations = language.translations || {};

	const dinoTranslations = translations[activeLanguage]
		?.dinoNdertimiTranslations || {
		en: {
			header: "DINO CONSTRUCTION",
			subHeader:
				"High Constructions and Successful Projects for a Better Future.",
			section1Title: "DINO CONSTRUCTION",
			section1Description:
				"DINO Construction is a leading company in the construction sector in Lipjan, which has earned a solid reputation for delivering high-quality projects that enhance infrastructure and improve the quality of life for the local community. With a team of skilled professionals and an unwavering commitment to high-quality standards, we have successfully completed projects in the residential, commercial, and public sectors.",
			section2Title: "Commitment to Quality and Innovation",
			section2Description:
				"Our commitment to quality and innovation is what makes us stand out in the industry. We use advanced technologies and modern methods to ensure excellent results, aiming to meet the highest international standards and delivering projects that exceed our clients' expectations.",
			section3Title: "Important Projects",
			section3Description:
				"DINO Construction is known for its large and complex projects. Our buildings have contributed to the development and modernization of the region, and each project is an opportunity to deliver value and ensure that our clients are fully satisfied with the results.",
			section4Title: "The Future",
			section4Description:
				"With a successful past and a bright future, DINO Construction is committed to continuing its growth and development. By offering high-quality projects that not only meet current needs but also anticipate future demands, we aim to remain a leader in the construction sector.",
			downloadPdfText: "Download catalogue",
		},
		sq: {
			header: "DINO NDËRTIMI",
			subHeader:
				"Ndërtime të Larta dhe Projekte të Suksesshme për Një Të Ardhme më të Mirë.",
			section1Title: "DINO NDËRTIMI",
			section1Description:
				"DINO Ndërtimi është një kompani lider në sektorin e ndërtimit në Lipjan, e cila ka fituar një reputacion të qëndrueshëm për realizimin e projekteve të cilësisë së lartë që përmirësojnë infrastrukturën dhe cilësinë e jetës për komunitetin lokal. Me një ekip profesionistësh të kualifikuar dhe përkushtim të palëkundur ndaj standardeve të larta të cilësisë, ne kemi përfunduar me sukses projekte në sektorët e banimit, komercial dhe publik.",
			section2Title: "Përkushtimi ndaj Cilësisë dhe Inovacionit",
			section2Description:
				"Përkushtimi ynë ndaj cilësisë dhe inovacionit është ajo që na bën të veçojmë në industri. Ne përdorim teknologji të avancuara dhe metoda moderne për të siguruar rezultate të shkëlqyera, duke synuar të përmbushim standardet më të larta ndërkombëtare dhe duke siguruar projekte që përmbushin pritshmëritë e klientëve tanë.",
			section3Title: "Projekte të Rëndësishme",
			section3Description:
				"DINO Ndërtimi është e njohur për projektet e mëdha dhe komplekse. Ndërtesat tona kanë kontribuar në zhvillimin dhe modernizimin e rajonit, dhe çdo projekt është një mundësi për të ofruar vlerë dhe për të siguruar që klientët tanë janë plotësisht të kënaqur me rezultatet.",
			section4Title: "E Ardhmja",
			section4Description:
				"Me një histori të suksesshme dhe një të ardhme të ndritshme, Dino Ndërtimi është e përkushtuar për të vazhduar rritjen dhe zhvillimin e saj. Duke ofruar projekte të cilësisë së lartë që jo vetëm plotësojnë nevojat e tanishme, por edhe parashikojnë ato të së ardhmes, ne synojmë të mbetemi një lider në sektorin e ndërtimit.",
			downloadPdfText: "Shkarko katalogun",
		},
	};

	const activeTranslations = dinoTranslations[activeLanguage];

	useEffect(() => {
		AOS.init({ duration: 1000, once: true });
	}, []);

	return (
		<div className="container mx-auto px-6 py-12">
			<div className="relative mb-12 bg-gray-900 rounded-lg overflow-hidden shadow-lg">
				<div
					className="absolute inset-0 bg-cover bg-bottom"
					style={{ backgroundImage: "url('assets/16.jpg')" }}
				/>
				<div className="absolute inset-0 bg-gradient-to-t from-red-900 to-transparent"></div>
				<div className="relative z-10 flex flex-col items-center justify-center h-64 text-center p-6">
					<h1
						className="text-5xl md:text-6xl font-extrabold text-white mb-4 leading-tight"
						data-aos="fade-down"
					>
						{activeTranslations.header}
					</h1>
					<p
						className="text-lg md:text-xl text-gray-200 max-w-4xl mx-auto"
						data-aos="fade-up"
					>
						{activeTranslations.subHeader}
					</p>
				</div>
			</div>

			<div className="space-y-16">
				<div
					className="relative grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 section-with-image"
					data-aos="fade-right"
				>
					<div className="bg-white p-8 rounded-lg shadow-lg flex flex-col justify-center items-center w-full">
						<h2 className="text-3xl font-semibold text-red-800 mb-4 text-center">
							{activeTranslations.section1Title}
						</h2>
						<p className="text-lg leading-relaxed text-gray-700 text-center">
							{activeTranslations.section1Description}
						</p>
					</div>
					<div className="relative section-image" data-aos="fade-left">
						<img
							src="assets/0104.jpg"
							alt="Construction Project"
							className="w-full sm:h-[300px] md:h-[350px] lg:h-auto object-cover object-center"
						/>
					</div>
				</div>

				<div className="bg-white p-8 rounded-lg shadow-lg" data-aos="fade-up">
					<h2 className="text-3xl font-semibold text-red-800 mb-4">
						{activeTranslations.section2Title}
					</h2>
					<p className="text-lg leading-relaxed text-gray-700">
						{activeTranslations.section2Description}
					</p>
				</div>

				<div
					className="relative grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 section-with-image"
					data-aos="fade-right"
				>
					<div
						className="bg-white p-8 rounded-lg shadow-lg"
						data-aos="fade-left"
					>
						<h2 className="text-3xl font-semibold text-red-800 mb-4">
							{activeTranslations.section3Title}
						</h2>
						<p className="text-lg leading-relaxed text-gray-700">
							{activeTranslations.section3Description}
						</p>
						<a
							href="/assets/Green Park.pdf"
							className="inline-block mt-6 px-6 py-3 text-red-800 font-semibold text-lg rounded-lg border-2 border-red-800 hover:text-white hover:bg-red-800"
							download
						>
							{activeTranslations.downloadPdfText}
						</a>
					</div>
					<div className="relative section-image">
						<img
							src="assets/dino7.jpg"
							alt="Important Project"
							className="rounded-lg shadow-md"
						/>
					</div>
				</div>

				<div className="bg-white p-8 rounded-lg shadow-lg" data-aos="fade-up">
					<h2 className="text-3xl font-semibold text-red-800 mb-4">
						{activeTranslations.section4Title}
					</h2>
					<p className="text-lg leading-relaxed text-gray-700">
						{activeTranslations.section4Description}
					</p>
				</div>
			</div>

			<section>
				<ContactUs />
			</section>
			<section>
				<Marquee />
			</section>
		</div>
	);
};

export default DinoNdertimi;
