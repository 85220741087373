import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";

const Counter = () => {
  // Get the active language from Redux
  const language = useSelector((state) => state.language.language); // Current language (e.g., "en", "sq")
  
  // Translations object, defined inside the Counter.js file
  const translations = {
    en: {
      achievements: "Our Achievements",
      products: "Products",
      clients: "Clients",
      completedProjects: "Completed Projects",
    },
    sq: {
      achievements: "Arritjet tona",
      products: "Produkte",
      clients: "Klientë",
      completedProjects: "Projekte të përfunduara",
    },
    // Add more languages here as needed
  };

  // Get the translations for the active language
  const counterTranslations = translations[language] || translations.en; // Fallback to English if the language is not found

  const [inView, setInView] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setInView(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the section is in view
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  return (
    <div className="bg-red-800 py-10" ref={counterRef}>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold text-white text-center mb-8">
          {counterTranslations.achievements}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <div className="text-center">
            <h3 className="text-5xl font-bold text-white">
              {inView && <CountUp start={0} end={1567} duration={3} prefix="+" />}
            </h3>
            <p className="text-xl text-white">{counterTranslations.products}</p>
          </div>
          <div className="text-center">
            <h3 className="text-5xl font-bold text-white">
              {inView && <CountUp start={0} end={2000} duration={3} prefix="+" />}
            </h3>
            <p className="text-xl text-white">{counterTranslations.clients}</p>
          </div>
          <div className="text-center">
            <h3 className="text-5xl font-bold text-white">
              {inView && <CountUp start={0} end={804} duration={3} prefix="+" />}
            </h3>
            <p className="text-xl text-white">{counterTranslations.completedProjects}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
