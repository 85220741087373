import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"; 

const Card = ({ image, title, description, link, customClass }) => {
  const language = useSelector((state) => state.language); 
  const activeLanguage = language.language || "en"; 

  const translations = {
    en: {
      readMore: "Read More",
    },
    sq: {
      readMore: "Më shumë",
    },
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className={`bg-white rounded-2xl shadow-lg overflow-hidden ${customClass}`}>
      <img src={image} alt={title} className="w-full h-48 object-cover rounded-t-2xl" />
      <div className="p-4 flex flex-col rounded-t-2xl items-center">
        <h3 className="text-lg font-bold mb-2 text-center">{truncateText(title, 20)}</h3>
        <p className="text-gray-700 mb-4 text-center">{truncateText(description, 100)}</p>
        <Link
          to={link}
          className="inline-block text-red-700 py-2 px-4 rounded hover:bg-red-700 hover:text-white transition duration-200 no-underline text-center"
        >
          {translations[activeLanguage]?.readMore || translations.en.readMore} 
        </Link>
      </div>
    </div>
  );
};

export default Card;
