const newsData = [
    {
        id: 1,
        // title: "Transformimi i Unazës së Prishtinës:",
        content: "Punimet tek Unaza e Prishtinës kanë filluar të marrin një formë krejtësisht tjetër falë kontributit të kompanisë Dino Company. Me një përkushtim të thellë dhe ekspertizë të konsoliduar në fushën e ndërtimit, kjo kompani ka luajtur një rol kyç në transformimin e projektit.",
        image: "/assets/67.jpg",
    },
    {
        id:5 ,
        // title: "Projekti Veternik:",
        content: "Ju njoftojmë se shumë shpejt do të fillojë ndërtimi i një objekti të ri modern, që do të ngrihet në Majën e Vetërnikut, një nga zonat më të bukura dhe më të kërkuara të Prishtinës. Ky objekt do të ofrojë kushte të shkëlqyera jetese, duke ofruar pamje të mrekullueshme dhe...",
        image: "/assets/veternik.jpeg",
    },
    {
        id: 3,
        // title: "Projekti Rruga Prishtinë-Gjilan:",
        content: "Pas një pune të palodhur dhe një përpjekje të vazhdueshme, rruga Prishtinë - Gjilan është pothuajse në përfundim. Ky projekt, një nga arteriet më të rëndësishme të lidhjes rajonale, ka kaluar nëpër disa faza të ndërlikuara ndërtimi, ku përkushtimi dhe profesionalizmi kanë qenë thelbësore për suksesin e tij.",
        image: "/assets/44.jpg",
    },
    {
        id: 4,
        // title: "Brezovica Hotel & SPA:",
        content: "Në mesin e një sërë projektesh të realizuara nga Dino Qeramika, Brezovica Hotel & SPA shquhet si një shembull i jashtëzakonshëm për përkushtimin në çdo detaj. Ky projekt është i veçantë jo vetëm për dizajnin estetik tërheqës, por edhe për përqendrimin maksimal në cilësi.",
        image: "/assets/hoteli.11.jpeg",
    },
    {
        id: 2,
        // title: "Salla e Sportit në Lipjan:",
        content: "Salla e sportit në Lipjan, e përfunduar disa muaj më parë, ofron një ambient të përshtatshëm për aktivitete sportive të ndryshme. Me ardhjen e pranverës, qytetarët e Lipjanit kanë mundësinë të përfitojnë nga infrastruktura e kësaj salle për të ushtruar dhe për të promovuar stërvitjen e shëndetshme.",
        image: "/assets/22.jpg",
    },
];

export default newsData;
