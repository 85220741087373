import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ContactUs from "../components/ContactUs";
import Marquee from "../components/Marquee";
import AOS from 'aos';
import 'aos/dist/aos.css';

const DinoGurethyes = () => {
  const language = useSelector((state) => state.language);
  const activeLanguage = language.language || "en";
  const translations = language.translations || {};

  const dinoGurethyesTranslations = translations[activeLanguage]?.dinoGurethyesTranslations || {
    en: {
      header: "DINO STONE-CRUSHER",
      subHeader: "High-Quality Crushing Solutions",
      section1Title: "DINO STONE-CRUSHER",
      section1Description:
        "DINO STONE-CRUSHER is a company specialized in the production and provision of gravel and stone materials for all types of construction. With a fleet of modern and professional machinery, the company produces a wide range of stone fractions, meeting the highest quality standards required in the market.",
      section1Description2:
        "We offer flexible solutions for various projects, providing materials that meet the technical specifications and unique needs of each client. Every product we deliver is tested and verified to guarantee maximum quality and durability.",
      section2Title: "Quality and Commitment",
      section2Description:
        "DINO STONE-CRUSHER aims to meet the needs of construction projects of all scales, from small buildings to large infrastructure projects. Quality and attention to detail are our top priorities, ensuring durable and high-performance materials to guarantee the success of every project.",
      section2Description2:
        "We believe that high-quality material is the key to successful construction. Therefore, we continuously invest in new technology and the training of our staff to maintain the highest production standards."
    },
    sq: {
      header: "DINO GURËTHYES",
      subHeader: "Zgjidhje Cilësore për Thyerjen e Gurit",
      section1Title: "DINO GURËTHYES",
      section1Description:
        "DINO Gurëthyesi është një kompani e specializuar në prodhimin dhe ofrimin e zhavorrit dhe materialeve të gurit për çdo lloj ndërtimi. Me një flotë të makinerive moderne dhe profesionale, kompania prodhon një gamë të gjerë të fraksioneve të gurit, të cilat përmbushin standardet më të larta të cilësisë që kërkohen në treg.",
      section1Description2:
        "Ne ofrojmë zgjidhje fleksibile për projekte të ndryshme, duke siguruar materiale që përmbushin specifikimet teknike dhe nevojat unike të çdo klienti. Çdo produkt që dërgojmë është testuar dhe verifikuar për të garantuar cilësinë maksimale dhe qëndrueshmëri.",
      section2Title: "Cilësia dhe Përkushtimi",
      section2Description:
        "DINO Gurëthyesi Mineral synon të plotësojë nevojat e projekteve ndërtimore të çdo shkalle, nga ndërtime të vogla deri te projekte të mëdha infrastrukturore. Cilësia dhe përkushtimi ndaj detajeve janë prioritetet kryesore, duke siguruar materiale të qëndrueshme dhe me performancë të lartë për të garantuar suksesin e çdo projekti.",
      section2Description2:
        "Ne besojmë se një material i cilësisë së lartë është çelësi për një ndërtim të suksesshëm. Prandaj, ne investojmë vazhdimisht në teknologji të reja dhe trajnimin e stafit tonë për të ruajtur standardet më të larta të prodhimit."
    }
  };

  const activeTranslations = dinoGurethyesTranslations[activeLanguage];

  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="container mx-auto px-6 py-12">
      <div className="relative mb-12 bg-gray-900 rounded-lg overflow-hidden shadow-2xl">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: "url('assets/03.-Betonjerka-post-1.jpg')" }}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-red-900 to-transparent"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-64 text-center p-6">
          <h1 data-aos="fade-up" className="text-5xl md:text-6xl font-extrabold text-white mb-4 leading-tight">
            {activeTranslations.header}
          </h1>
          <p data-aos="fade-up" data-aos-delay="200" className="text-lg md:text-xl text-gray-200 max-w-4xl mx-auto">
            {activeTranslations.subHeader}
          </p>
        </div>
      </div>

      <section className="space-y-16">
  <div className="relative grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8">
    <div data-aos="fade-right" className="relative">
      <img
        src="assets/Dinogurethyes.jpg"
        alt="DINO Gurthyesi Mineral"
        className="w-full sm:h-[300px] md:h-[350px] lg:h-auto object-cover object-center"
        />
      <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
    </div>

    <div data-aos="fade-left" className="bg-white p-8 rounded-lg shadow-lg flex flex-col justify-center items-center">
      <h2 className="text-3xl font-semibold text-red-800 mb-4 text-center">
        {activeTranslations.section1Title}
      </h2>
      <p className="text-lg leading-relaxed text-gray-700 text-center">
        {activeTranslations.section1Description}
      </p>
      <p className="text-lg leading-relaxed text-gray-700 text-center mt-4">
        {activeTranslations.section1Description2}
      </p>
    </div>
  </div>

  <section data-aos="fade-up">
    <div className="md:w-full bg-white p-8 rounded-lg shadow-2xl transform hover:translate-y-2 transition-transform duration-300 ease-in-out">
      <h2 className="text-3xl md:text-4xl font-extrabold text-red-700 mb-6">
        {activeTranslations.section2Title}
      </h2>
      <p className="text-lg leading-relaxed mb-6 text-gray-700">
        {activeTranslations.section2Description}
      </p>
      <p className="text-lg leading-relaxed mb-6 text-gray-700">
        {activeTranslations.section2Description2}
      </p>
    </div>
  </section>
</section>


      <section data-aos="fade-up">
        <ContactUs />
      </section>

      <section data-aos="fade-up">
        <Marquee />
      </section>
    </div>
  );
};

export default DinoGurethyes;
