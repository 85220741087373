import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";

const newsData = [
	{
		id: 5,
		title: {
			en: "Dino Residence",
			sq: "Dino Residence",
		},
		content: {
			en: "We are excited to announce the upcoming construction of a new, modern building in Maja e Veternikut, one of the most beautiful and sought-after areas in Pristina. This project offers excellent living conditions, with stunning views and a convenient location close to essential city services and infrastructure.",
			sq: "Jemi të lumtur të njoftojmë nisjen e ndërtimit të një objekti të ri dhe modern në Majën e Vetërnikut, një nga zonat më të bukura dhe më të kërkuara të Prishtinës. Ky projekt ofron kushte të shkëlqyera jetese, me pamje të mrekullueshme dhe një lokacion të përshtatshëm, pranë shërbimeve dhe infrastrukturës kryesore të qytetit.",
		},
		image: "/assets/veternik.jpeg",
	},
	{
		id: 1,
		title: {
			en: "Transformation of the Pristina Ring Road",
			sq: "Transformimi i Unazës së Prishtinës",
		},
		content: {
			en: "The works on the Pristina Ring Road have begun to take on a completely different form thanks to the contribution of Dino Company. With a deep commitment and consolidated expertise in the construction field, this company has played a key role in the transformation of the project.",
			sq: "Punimet tek Unaza e Prishtinës kanë filluar të marrin një formë krejtësisht tjetër falë kontributit të kompanisë Dino Company. Me një përkushtim të thellë dhe ekspertizë të konsoliduar në fushën e ndërtimit, kjo kompani ka luajtur një rol kyç në transformimin e projektit.",
		},
		image: "/assets/67.jpg",
	},
	
	{
		id: 3,
		title: {
			en: "Pristina-Gjilan Road Project",
			sq: "Projekti Rruga Prishtinë-Gjilan",
		},
		content: {
			en: "After tireless work and ongoing effort, the Pristina - Gjilan road is almost complete. This project, one of the most important regional connection arteries, has gone through several complex construction phases, where commitment and professionalism have been essential for its success.",
			sq: "Pas një pune të palodhur dhe një përpjekje të vazhdueshme, rruga Prishtinë - Gjilan është pothuajse në përfundim. Ky projekt, një nga arteriet më të rëndësishme të lidhjes rajonale, ka kaluar nëpër disa faza të ndërlikuara ndërtimi, ku përkushtimi dhe profesionalizmi kanë qenë thelbësore për suksesin e tij.",
		},
		image: "/assets/44.jpg",
	},
	{
		id: 4,
		title: {
			en: "Brezovica Hotel & SPA",
			sq: "Brezovica Hotel & SPA",
		},
		content: {
			en: "Amid a series of projects completed by Dino Qeramika, Brezovica Hotel & SPA stands out as an extraordinary example of commitment to every detail. This project is special not only for its appealing aesthetic design but also for its maximum focus on quality.",
			sq: "Në mesin e një sërë projektesh të realizuara nga Dino Qeramika, Brezovica Hotel & SPA shquhet si një shembull i jashtëzakonshëm për përkushtimin në çdo detaj. Ky projekt është i veçantë jo vetëm për dizajnin estetik tërheqës, por edhe për përqendrimin maksimal në cilësi.",
		},
		image: "/assets/hoteli.11.jpeg",
	},
	{
		id: 2,
		title: {
			en: "Sports Hall in Lipjan",
			sq: "Salla e Sportit në Lipjan",
		},
		content: {
			en: "The sports hall in Lipjan, completed a few months ago, offers a suitable environment for various sports activities. With the arrival of spring, the citizens of Lipjan have the opportunity to benefit from the infrastructure of this hall to exercise and promote healthy training.",
			sq: "Salla e sportit në Lipjan, e përfunduar disa muaj më parë, ofron një ambient të përshtatshëm për aktivitete sportive të ndryshme. Me ardhjen e pranverës, qytetarët e Lipjanit kanë mundësinë të përfitojnë nga infrastruktura e kësaj salle për të ushtruar dhe për të promovuar stërvitjen e shëndetshme.",
		},
		image: "/assets/22.jpg",
	},
	
];

const News = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });

		AOS.init({
			duration: 1000,
			easing: "ease-in-out",
			once: true,
		});
	}, []);

	const language = useSelector((state) => state.language);
	const currentLanguage = language?.language || "en";

	const [, setIsMobile] = useState(window.innerWidth <= 1024);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 1024);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const translations = {
		en: {
			pageTitle: "Latest News",
			pageSubtitle: "Stay up-to-date with the latest news from our company.",
			readMore: "Read More",
		},
		sq: {
			pageTitle: "Lajmet e Fundit",
			pageSubtitle:
				"Qëndroni të përditësuar me lajmet më të fundit nga kompania jonë.",
			readMore: "Më shumë",
		},
	};

	const activeTranslations = translations[currentLanguage] || translations.en;

	return (
		<div className="container mx-auto px-4 py-8">
			<div className="relative mb-12 bg-gray-800 rounded-lg overflow-hidden shadow-lg">
				<div
					className="absolute inset-0 bg-cover bg-center"
					style={{ backgroundImage: `url('/assets/whoAreWe.jpg')` }}
				/>
				<div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
				<div className="relative z-10 flex flex-col items-center justify-center h-48 md:h-64 text-center p-4 md:p-6">
					<h1 className="text-3xl md:text-5xl font-bold text-white mb-2 md:mb-4 leading-tight">
						{activeTranslations.pageTitle}
					</h1>
					<p className="text-base md:text-xl text-white max-w-2xl mx-auto">
						{activeTranslations.pageSubtitle}
					</p>
				</div>
			</div>

			<div className="flex flex-col space-y-6">
				{newsData.map((article) => (
					<div
						key={article.id}
						className="bg-white w-full rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row"
						data-aos="fade-up"
					>
						<div className="relative w-full md:w-1/3 h-48 md:h-64">
							<div className="absolute inset-0 bg-black opacity-20"></div>
							<img
								src={article.image}
								alt={article.title[currentLanguage]}
								className="w-full h-full object-cover"
							/>
						</div>

						<div className="p-4 md:p-6 w-full md:w-2/3">
							<h2 className="text-xl md:text-2xl font-semibold mb-2">
								{article.title[currentLanguage]}
							</h2>
							<p className="text-gray-600 mb-4">
								{article.content[currentLanguage]}
							</p>
							<Link
								to={`/news/${article.id}`}
								className="inline-block text-red-700 py-2 px-4 rounded hover:bg-red-700 hover:text-white transition duration-200 no-underline mt-2"
							>
								{activeTranslations.readMore}
							</Link>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default News;
