import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/language/LanguageSlice";

function LanguagePicker() {
	const dispatch = useDispatch();
	const language = useSelector((state) => state.language.language);

	const handleLanguageChange = (lang) => {
		console.log(`Changing language to: ${lang}`);
		dispatch(changeLanguage(lang));
	};

	return (
		<div className="flex gap-3 items-center">
			<button
				className={`hover:text-red-600 ${language === "sq" && "text-red-600"}`}
				onClick={() => handleLanguageChange("sq")}
			>
				AL
			</button>
      <span className="w-0.5 h-6 bg-gray-200"></span>
			<button
				className={`hover:text-red-600 ${
					language === "en" && "text-red-600"
				}`}
				onClick={() => handleLanguageChange("en")}
			>
				EN
			</button>
		</div>
	);
}

export default LanguagePicker;
