import React, { useState, useEffect } from "react";
import { Carousel as BootstrapCarousel } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";

const slideVariants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -50 },
};

const letterVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
};

const Carousel = () => {
  const [showText, setShowText] = useState(true);
  const language = useSelector((state) => state.language.language);
  const translations = useSelector(
    (state) => state.language.translations[language]
  );

  useEffect(() => {
    setShowText(false);
    const timer = setTimeout(() => {
      setShowText(true);
    }, 300);
    return () => clearTimeout(timer);
  }, [language]);

  const getTextLetters = (text) => {
    return text.split(" ").map((word, wordIndex) => (
      <span key={wordIndex}>
        {word.split("").map((letter, letterIndex) => (
          <motion.span
            key={letterIndex}
            variants={letterVariants}
            initial="initial"
            animate="animate"
            transition={{
              duration: 0.1,
              delay: (wordIndex * text.split(" ").length + letterIndex) * 0.05,
            }}
            style={{ display: "inline-block" }}
          >
            {letter}
          </motion.span>
        ))}
        {wordIndex < text.split(" ").length - 1 && " "}
      </span>
    ));
  };

  return (
    <div className="relative w-full bg-black overflow-hidden">
      <BootstrapCarousel
        fade
        className="absolute top-0 left-0 w-full h-full"
        interval={3000} 
        pause={false} 
      >
        {[1, 2, 3].map((item, index) => (
          <BootstrapCarousel.Item key={index}>
            <div className="relative w-full h-[250px] md:h-[700px]">
              <img
                className="d-block w-full h-full object-cover"
                src={
                  item === 1
                    ? "/assets/01Banner.jpg"
                    : item === 2
                    ? "/assets/Banner3.jpg"
                    : "/assets/Banner2.jpg"
                }
                alt={`Banner ${item}`}
              />
              <div
                className="absolute inset-0"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)", 
                  zIndex: 1,
                }}
              ></div>
              <AnimatePresence>
                {showText && translations?.carousel && (
                  <motion.div
                    key={index}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={slideVariants}
                    transition={{
                      duration: 0.5,
                      ease: "easeInOut",
                    }}
                    className="absolute inset-0 flex items-center justify-center z-10 p-4"
                  >
                    <div className="text-center">
                      <p
                        className="text-white text-[2rem] md:text-[4rem] font-abc"
                        style={{
                          lineHeight: "1.2",
                          fontFamily: "Luthon Southard Script",
                        }}
                      >
                        {getTextLetters(translations.carousel.welcome)}
                      </p>
                      <h1
                        className="text-white text-2xl md:text-5xl font-extrabold"
                        style={{ lineHeight: "1.2" }}
                      >
                        {getTextLetters(translations.carousel.title)}
                      </h1>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </BootstrapCarousel.Item>
        ))}
      </BootstrapCarousel>
    </div>
  );
};

export default Carousel;
