import React from "react";
import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import DinoNdertimi from "./pages/DinoNdertimi";
import DinoQeramika from "./pages/DinoQeramika";
import DinoGurethyes from "./pages/DinoGurethyes";
import DinoBeton from "./pages/DinoBeton";
import DinoAsfalt from "./pages/DinoAsfalt";
import News from "./pages/News";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import "./main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NewsDetail from "./components/NewsDetail";
import ScrollToTop from "./components/ScrollToTop";
import "aos/dist/aos.css";


function App() {
  return (
    <Provider store={store}>
      <ScrollToTop />
      <Header />
      <div className="mt-[80px] font-poppins">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dino-ndertimi" element={<DinoNdertimi />} />
          <Route path="/dino-qeramika" element={<DinoQeramika />} />
          <Route path="/dino-gurethyes" element={<DinoGurethyes />} />
          <Route path="/dino-beton" element={<DinoBeton />} />
          <Route path="/dino-asfalt" element={<DinoAsfalt />} />
          <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        </Routes>
      </div>
      <Footer />
    </Provider>
  );
}

export default App;
