import React from "react";

function ExpandableContent({ content }) {
  // Ensure content is defined and a string
  if (!content || typeof content !== 'string') {
    return <p>No content available</p>; // Return a fallback message if content is invalid
  }

  return <p>{content}</p>;
}

export default ExpandableContent;
