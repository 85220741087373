import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./language/LanguageSlice";
import cartReducer from "./cart/cartSlice";

const store = configureStore({
	reducer: {
		language: languageReducer,
		cart: cartReducer,
	},
});

export default store;
