import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import Card from "./Card";
import AOS from "aos";
import "aos/dist/aos.css";

const Cards = () => {
	const [currentSlide, setCurrentSlide] = useState(0);

	const language = useSelector((state) => state.language);
	const activeLanguage = language.language || "en";
	const translations = language.translations || {};

	const njesite = translations[activeLanguage]?.njesite || {};
	const cards = translations[activeLanguage]?.cards || {};

	const cardData = [
		{
			image: "/assets/13.jpeg",
			title: njesite.dinoQeramika || "Default Title",
			description: cards.dinoQeramika || "Default Description",
			link: "/dino-qeramika",
		},
		{
			image: "/assets/11.jpg",
			title: njesite.dinoAsfalt || "Default Title",
			description: cards.dinoAsfalt || "Default Description",
			link: "/dino-asfalt",
		},
		{
			image: "/assets/12.jpeg",
			title: njesite.dinoNdertime || "Default Title",
			description: cards.dinoNdertime || "Default Description",
			link: "/dino-ndertimi",
		},
		{
			image: "/assets/betonCard.jpg",
			title: njesite.dinoBeton || "Default Title",
			description: cards.dinoBeton || "Default Description",
			link: "/dino-beton",
		},
		{
			image: "/assets/14.jpg",
			title: njesite.dinoGurethyes || "Default Title",
			description: cards.dinoGurethyes || "Default Description",
			link: "/dino-gurethyes",
		},
	];

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: "0",
		autoplay: true,
		autoplaySpeed: 3000,
		beforeChange: (current, next) => setCurrentSlide(next),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	useEffect(() => {
		AOS.init({
			duration: 1000,
			once: true,
		});
	}, []);

	return (
		<div
			className="pt-44 pb-10 relative mx-auto sm:bg-slate-50"
			style={{ width: "80%" }}
			data-aos="fade-up"
		>
			<Slider {...settings}>
				{cardData.map((data, index) => (
					<div
						key={index}
						className={`px-2 ${currentSlide === index ? "" : "opacity-60"}`}
					>
						<Card
							image={data.image}
							title={data.title}
							description={data.description}
							link={data.link}
							customClass={
								currentSlide === index
									? "transform scale-110 z-10"
									: "transform scale-90 rounded-t-2xl"
							}
						/>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default Cards;
