import React, { useState } from "react";
import { useSelector } from "react-redux";
import ExpandableContent from "./ExpandableContent";

function HistorySection() {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isConsolidationExpanded, setIsConsolidationExpanded] = useState(false);

  const language = useSelector((state) => state.language);
  const activeLanguage = language.language || "en";
  const translations = language.translations || {};

  const historyTranslations = translations[activeLanguage]
    ?.historyTranslations || {
    title: "Historia dhe Evolucioni",
    consolidationTitleHeading: "Konsolidimi",
    showMore: "Shfaq më shumë ↓",
    showLess: "Shfaq më pak ↑",
    description:
      "E themeluar në vitin 2000, Kompania DINO filloi si një biznes individual me një vizion të qartë: të vendosë standarde të reja në industrinë e ndërtimit duke ofruar cilësi të jashtëzakonshme, zgjidhje inovative dhe përkushtim të pashembullt për klientët tanë. Me kalimin e viteve, ne jemi rritur në një emër të besuar në ndërtim dhe furnizim me materiale premium, duke fituar një reputacion si lider në këtë fushë.",
    foundingTitle: "Ne operojmë përmes pesë degëve të specializuara:",
    foundingPoints: [
      {
        label: "DINO QERAMIKA:",
        text: "Furnizimi i qeramikës më të mirë, duke përfshirë importet ekskluzive nga prodhuesit më të njohur të Italisë.",
      },
      {
        label: "DINO NDËRTIMI:",
        text: "Ofrimi i zgjidhjeve të ndërtimit me çelës në dorë që plotësojnë dhe tejkalojnë standardet e industrisë.",
      },
      {
        label: "DINO GURTHYESI:",
        text: "Ekspertë në prodhimin e zhavorrit dhe agregatit për të gjitha kërkesat e ndërtimit.",
      },
      {
        label: "DINO BETONI:",
        text: " Prodhimi i betonit me cilësi të lartë të përshtatur me specifikimet e projektit, me transport të besueshëm në vendin tuaj.",
      },
      {
        label: "DINO ASFALTI:",
        text: " Udhëheqës në prodhimin dhe aplikimin e saktë të asfaltit për rrugë dhe infrastrukturë.",
      },
    ],
    expansionTitle:
      "Përveç ekspertizës sonë të gjerë të ndërtimit, ne jemi krenarë që zotërojmë Brezovica Hotel & Spa, një nga destinacionet kryesore të Kosovës për relaksim dhe luks. I vendosur në peizazhin befasues të Brezovicës, hoteli ynë ndërthur elegancën moderne me shërbimin e pashembullt, duke u ofruar mysafirëve një eksperiencë të paharrueshme. Me interier të klasit botëror, një spa luksoze dhe një përkushtim për përsosmërinë e mikpritjes, Brezovica Hotel & Spa pasqyron përkushtimin e kompanisë sonë për cilësinë në gjithçka që bëjmë.",
    consolidationTitle:
      "Gama jonë e plotë e shërbimeve përfshin prodhimin e betonit, transportin e materialeve, prodhimin e zhavorrit dhe agregatit, furnizimin me qeramikë dhe prodhimin dhe aplikimin e asfaltit. Ne krenohemi që menaxhojmë pa probleme çdo aspekt të procesit të ndërtimit, duke siguruar efikasitet, saktësi dhe përsosmëri.",
    textTitle:
      "Ajo që e veçon DINO Company është përkushtimi ynë i palëkundur ndaj cilësisë dhe ndjekja jonë e pamëshirshme për inovacion. Për më shumë se dy dekada, ne kemi ndërtuar marrëdhënie të forta dhe të qëndrueshme me klientët tanë duke ofruar vazhdimisht rezultate të jashtëzakonshme.",
    partnerTitle:
      "Partneriteti me DINO Company do të thotë të zgjedhësh besueshmërinë, ekspertizën dhe angazhimin për të sjellë në jetë vizionin tënd. Me ne, ju mund të jeni të sigurt në arritjen e suksesit të jashtëzakonshëm në çdo projekt.",
    additionalDetails:
      "Dino Company gjithashtu zotëron Brezovica Hotel & Spa, një destinacion luksi në peizazazhin mahnitës të Brezovicës. Hoteli kombinon elegancën moderne me shërbime të klasit botëror, duke ofruar një eksperiencë të paharrueshme për vizitorët.",
  };

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const toggleConsolidationText = () => {
    setIsConsolidationExpanded(!isConsolidationExpanded);
  };

  const getTruncatedText = (text, length) => {
    if (!text) return "";  // Ensure the text is defined
    if (text.length <= length) return text;
    return `${text.substring(0, length)}...`;
  };

  return (
    <div className="flex flex-col md:flex-row md:space-x-8">
      {/* Left Section */}
      <div className="md:w-1/2">
        {/* Desktop View */}
        <div className="hidden lg:block">
          <h3 className="text-2xl font-semibold text-red-800 mb-4">
            {historyTranslations.title}
          </h3>
          <p className="text-lg leading-relaxed mb-6">
            {historyTranslations.description}
          </p>
          <div className="space-y-6">
            {/* Founding Section */}
            <div>
              <h4 className="text-xl font-semibold text-red-800 mb-2">
                {historyTranslations.foundingTitle}
              </h4>
              <ul className="list-disc list-inside text-lg leading-relaxed">
                {historyTranslations.foundingPoints?.map((point, index) => (
                  <li key={index}>
                    <strong>{point.label}</strong> {point.text}
                  </li>
                ))}
              </ul>
            </div>
            {/* Consolidation Section */}
            <div>
              <h4 className="text-xl font-semibold text-red-800 mb-2">
                {historyTranslations.consolidationTitleHeading || "Konsolidimi"}
              </h4>
              <p className="text-lg leading-relaxed">
                {isConsolidationExpanded
                  ? historyTranslations.consolidationTitle
                  : getTruncatedText(
                      historyTranslations.consolidationTitle,
                      180
                    )}
              </p>
              <button
                onClick={toggleConsolidationText}
                className="text-red-800 text-sm font-semibold mt-2"
              >
                {isConsolidationExpanded
                  ? historyTranslations.showLess || "Shfaq më pak ↑"
                  : historyTranslations.showMore || "Shfaq më shumë ↓"}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile View */}
        <div className="lg:hidden">
          <button
            className="w-full py-4 px-6 text-lg font-semibold text-red-800 text-left rounded-md border-b-2 hover:bg-red-100"
            onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
          >
            {historyTranslations.title}
            <span className="float-right">{isDescriptionOpen ? "↑" : "↓"}</span>
          </button>
          {isDescriptionOpen && (
            <div className="pl-4 py-4 bg-slate-50 rounded-md">
              <p className="text-lg leading-relaxed mb-4">
                {historyTranslations.description}
              </p>
            </div>
          )}

          {[
            "foundingTitle",
            "expansionTitle",
            "consolidationTitle",
            "textTitle",
            "partnerTitle",
            "additionalDetails",
          ].map((section, index) => (
            <div key={index}>
              <button
                className="w-full py-4 px-6 text-lg font-semibold text-red-800 text-left rounded-md border-b-2 hover:bg-red-100"
                onClick={() => toggleAccordion(index)}
              >
                {getTruncatedText(historyTranslations[section], 60)}
                <span className="float-right">
                  {activeAccordion === index ? "↑" : "↓"}
                </span>
              </button>
              {activeAccordion === index && (
                <div className="pl-4 py-4 bg-slate-50 rounded-md">
                  {section === "expansionTitle" ||
                  section === "consolidationTitle" ||
                  section === "textTitle" ||
                  section === "partnerTitle" ||
                  section === "additionalDetails" ? (
                    <ExpandableContent
                      content={historyTranslations[section] || ""}
                    />
                  ) : (
                    <ul className="list-disc list-inside text-lg leading-relaxed">
                      {historyTranslations[
                        `${section.replace("Title", "Points")}`
                      ]?.map((point, i) => (
                        <li key={i}>
                          <strong>{point.label}</strong> {point.text}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Right Section (Image) */}
      <div className="relative md:w-1/2 mb-8 md:mb-0">
        <img
          src="assets/27-NENTOR.jpg"
          alt="Company History"
          className="w-full h-auto bg-slate-100 rounded-lg shadow-lg transition-transform transform hover:scale-105"
        />
      </div>
    </div>
  );
}

export default HistorySection;
