import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaPhoneAlt, FaEnvelope, FaFacebookF } from "react-icons/fa";
import ContactUs from "../components/ContactUs";
import Marquee from "../components/Marquee";
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
	const language = useSelector((state) => state.language);
	const activeLanguage = language.language || "en";
	const translations = language.translations || {};

	const contactTranslations = translations[activeLanguage]?.contactTranslations || {
		en: {
			header: "CONTACT US",
			subHeader: "We are here to assist you with any inquiries.",
			generalInquiries: "GENERAL INQUIRIES",
			phoneLabel: "Phone",
			emailLabel: "Email",
			facebookLabel: "Facebook",
			contactForm: "Contact Us!",
			namePlaceholder: "Name*",
			emailPlaceholder: "Email Address*",
			cityPlaceholder: "City*",
			messagePlaceholder: "Message*",
			submitButton: "Submit",
			nameError: "Please enter your name (letters only).",
			emailError: "Please enter a valid email address.",
			cityError: "Please enter your city (letters only).",
			messageError: "Please enter your message.",
			successMessage: "Message sent successfully!",
			errorMessage: "An error occurred. Please try again later.",
		},
		sq: {
			header: "NA KONTAKTONI!",
			subHeader: "Jemi këtu për t'ju ndihmuar me çdo kërkesë.",
			generalInquiries: "PËR MË SHUMË INFO",
			phoneLabel: "Telefoni",
			emailLabel: "Email",
			facebookLabel: "Facebook",
			contactForm: "Na Kontaktoni!",
			namePlaceholder: "Emri*",
			emailPlaceholder: "Adresa e Emailit*",
			cityPlaceholder: "Qyteti*",
			messagePlaceholder: "Mesazhi*",
			submitButton: "Dërgo",
			nameError: "Ju lutemi, shkruani emrin tuaj (vetëm shkronja).",
			emailError: "Ju lutemi, shkruani një adresë emaili të vlefshme.",
			cityError: "Ju lutemi, shkruani qytetin tuaj (vetëm shkronja).",
			messageError: "Ju lutemi, shkruani mesazhin tuaj.",
			successMessage: "Mesazhi u dërgua me sukses!",
			errorMessage: "Ndodhi një gabim. Ju lutemi provoni përsëri më vonë.",
		},
	};

	const activeTranslations = contactTranslations[activeLanguage];

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		city: "",
		message: "",
	});

	const [errors, setErrors] = useState({});
	const [successMessage, setSuccessMessage] = useState("");

	const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
    }));
    setSuccessMessage(""); 
};


	const isAlpha = (str) => /^[A-Za-z\s]+$/.test(str);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSuccessMessage(""); 
		const newErrors = {};

		if (!formData.name) {
			newErrors.name = activeTranslations.nameError;
		} else if (!isAlpha(formData.name)) {
			newErrors.name = activeTranslations.nameError;
		}

		if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
			newErrors.email = activeTranslations.emailError;
		}

		if (!formData.city) {
			newErrors.city = activeTranslations.cityError;
		} else if (!isAlpha(formData.city)) {
			newErrors.city = activeTranslations.cityError;
		}

		if (!formData.message) {
			newErrors.message = activeTranslations.messageError;
		}

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		const emailBody = {
			name: formData.name,
			email: formData.email,
			city: formData.city,
			description: formData.message,
		};

		try {
			const rawResponse = await fetch(
				"https://www.dino-company.a2hosted.com/contact",
				{
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify(emailBody),
				}
			);
			const content = await rawResponse.json();

			if (content.status === "success") {
				setFormData({ name: "", email: "", city: "", message: "" });
				setSuccessMessage(activeTranslations.successMessage);
			} else {
				setSuccessMessage(activeTranslations.errorMessage);
			}
		} catch (e) {
			setSuccessMessage(activeTranslations.errorMessage);
		}
	};

	useEffect(() => {
		AOS.init({ duration: 1000 }); 
	}, []);

	return (
		<div className="container mx-auto px-6 py-12">
			<div
				className="relative mb-12 bg-gray-900 rounded-lg overflow-hidden shadow-2xl"
				data-aos="fade-right"
			>
				<div
					className="absolute inset-0 bg-cover bg-center"
					style={{ backgroundImage: "url('assets/whoAreWe.jpg')" }}
				/>
				<div className="absolute inset-0 bg-gradient-to-t from-red-900 to-transparent"></div>
				<div className="relative z-10 flex flex-col items-center justify-center h-64 text-center p-6">
					<h1 className="text-5xl md:text-6xl font-extrabold text-white mb-4 leading-tight">
						{activeTranslations.header}
					</h1>
					<p className="text-lg md:text-xl text-gray-200 max-w-4xl mx-auto">
						{activeTranslations.subHeader}
					</p>
				</div>
			</div>

			<div className="grid grid-cols-1 pt-20 pb-20 md:grid-cols-2 gap-8">
				<div className="pt-28" data-aos="fade-right">
					<h2 className="text-2xl font-bold mb-4">
						{activeTranslations.generalInquiries}
					</h2>
					<div className="flex flex-col items-start space-y-4 mb-8">
						<div className="flex items-center space-x-4">
							<FaPhoneAlt className="text-3xl text-red-700" />
							<a
								href="tel:+38344595959"
								className="text-lg no-underline text-red-700"
							>
								+383 44 595 959
							</a>
						</div>
						<div className="flex items-center space-x-4">
							<FaEnvelope className="text-3xl text-red-700" />
							<a
								href="mailto:info@dino-company.com"
								className="text-lg no-underline text-red-700"
							>
								info@dino-company.com
							</a>
						</div>
						<div className="flex items-center space-x-4">
							<FaFacebookF className="text-3xl text-red-700" />
							<a
								href="https://www.facebook.com/dinocompanykosova/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-lg no-underline text-red-700"
							>
								Dino Company
							</a>
						</div>
					</div>
				</div>

				<div data-aos="fade-right">
					<h2 className="text-2xl font-bold mb-4">
						{activeTranslations.contactForm}
					</h2>
					<div className="flex flex-col space-y-4">
						<input
							type="text"
							name="name"
							value={formData.name}
							onChange={handleChange}
							placeholder={activeTranslations.namePlaceholder}
							className={`p-3 border ${
								errors.name ? "border-red-500" : "border-gray-300"
							} rounded-md focus:outline-none focus:ring-2 focus:ring-red-700`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm">{errors.name}</p>
						)}
						<input
							type="email"
							name="email"
							value={formData.email}
							onChange={handleChange}
							placeholder={activeTranslations.emailPlaceholder}
							className={`p-3 border ${
								errors.email ? "border-red-500" : "border-gray-300"
							} rounded-md focus:outline-none focus:ring-2 focus:ring-red-700`}
						/>
						{errors.email && (
							<p className="text-red-500 text-sm">{errors.email}</p>
						)}
						<input
							type="text"
							name="city"
							value={formData.city}
							onChange={handleChange}
							placeholder={activeTranslations.cityPlaceholder}
							className={`p-3 border ${
								errors.city ? "border-red-500" : "border-gray-300"
							} rounded-md focus:outline-none focus:ring-2 focus:ring-red-700`}
						/>
						{errors.city && (
							<p className="text-red-500 text-sm">{errors.city}</p>
						)}
						<textarea
							name="message"
							value={formData.message}
							onChange={handleChange}
							placeholder={activeTranslations.messagePlaceholder}
							className={`p-3 border ${
								errors.message ? "border-red-500" : "border-gray-300"
							} rounded-md focus:outline-none focus:ring-2 focus:ring-red-700`}
						/>
						{errors.message && (
							<p className="text-red-500 text-sm">{errors.message}</p>
						)}
						<button
							onClick={handleSubmit}
							className="bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded-lg"
						>
							{activeTranslations.submitButton}
						</button>
						{successMessage && (
							<p className="text-green-600 text-sm mt-2">{successMessage}</p>
						)}
					</div>
				</div>
			</div>
			<ContactUs />
      <Marquee />

		</div>
	);
};

export default Contact;
