import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import MarqueeItemSmall from "../components/MarqueeItemSmall";
import Marquee from "../components/Marquee";
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Translations from "../redux/language/Translations";

const DinoQeramika = () => {
  const language = useSelector((state) => state.language);
  const activeLanguage = language.language || "en";
  const translations = language.translations || {};

  const dinoQeramikaTranslations = translations[activeLanguage]?.dinoQeramikaTranslations || {
    en: {
      header: "DINO CERAMICS",
      subHeader: "High-Quality Ceramics",
      section1Title: "DINO CERAMICS",
      section1Description:
        "Dino Ceramics is a well-known company in Lipjan, specializing in the production and trade of ceramic materials. With a wide range of products that include tiles, flooring, and various accessories, Dino Ceramics offers solutions for different construction and renovation projects. The company is committed to high quality, using advanced materials and modern technology in the production process. The Dino Ceramics team consists of skilled professionals who work to provide products that meet the highest standards and customer needs.",
      section2Title: "Commitment to Quality",
      section2Description:
        "Dino Ceramics places quality as a top priority in every aspect of its operations. We continuously invest in modern technology and advanced materials to ensure that our products meet the highest industry standards. Every product undergoes rigorous testing to ensure that our clients receive only the best. This quality-focused philosophy helps maintain customer trust and strengthens our reputation in the construction and renovation market.",
      section3Title: "International Partnerships",
      section3Description:
        "A key factor in our commitment to quality is our strong connections with renowned international companies, including:",
        partnerList: [
          { name: "AZUVI SPAIN", description: "Azuvi Ceramics offers high-quality tiles with modern and elegant designs, suitable for any residential or commercial space." },
          { name: "SERAMIKSAN TURKEY", description: "Delivers high-quality products that meet diverse market needs." },
          { name: "ECO SPAIN", description: "Eco Cerámica Spanjë prodhon pllaka qeramike cilësore, me dizajne moderne dhe fokus në inovacion." },
          { name: "MIRAGE ITALY", description: "Known for its modern and innovative style, this company enhances our product portfolio's diversity." },
          { name: "LINEART TURKEY", description: "Lineart Banyo Ceramic offers modern and aesthetic solutions for bathrooms, combining quality with innovative design." },
          { name: "CERRAD POLAND", description: "Offers a wide range of products, enabling Dino Ceramics to provide tailored solutions for various construction projects." },
          { name: "ETILI TURKEY", description: "A well-known company in the ceramic industry, offering high-quality products and solutions that contribute to enriching our portfolio with aesthetically pleasing and durable materials." },
          { name: "KERAKOLL ITALY", description: "Kerakoll provides innovative and eco-friendly building materials, specializing in solutions that promote sustainability and high performance." },
          { name: "MOSSCA CERAMICS", description: "Mossaica Ceramics offers a unique collection of artistic and decorative tiles, blending tradition with modernity for exceptional designs." },
      ],
      
      contact: {
        title: "Visit Our Showroom",
        subtitle: "Experts in Solutions, Partners in Success",
        addressHeading: "Our Address",
        address: "14000, ST. Prishtina - Ferizaj",
        workingHours: {
          title: "Working Hours",
          mondayToFriday: "Monday - Friday: 8am - 6pm",
          saturday: "Saturday: 9am - 5pm",
          sunday: "Sunday: Closed",
        },
        contact: {
          title: "Contact",
          email: "Email: info@dino-company.com",
          phone: "Phone: 038 566 666",
        },
      },
    },
    sq: {
      header: "DINO QERAMIKA",
      subHeader: "Cilësi e Lartë e Qeramikës",
      section1Title: "DINO QERAMIKA",
      section1Description:
        "Dino Qeramika është një kompani e njohur në Lipjan, e specializuar në prodhimin dhe tregtimin e materialeve të qeramiks. Me një gamë të gjerë produktesh që përfshijnë pllaka, dysheme dhe aksesorë të ndryshëm, Dino Qeramika ofron zgjidhje të ndryshme për projekte ndërtimi dhe renovimi. Kompania është e angazhuar për cilësi të lartë, duke përdorur materiale të avancuara dhe teknologji moderne në procesin e prodhimit. Ekipi i Dino Qeramika përbëhet nga profesionistë të kualifikuar, të cilët punojnë për të ofruar produkte që plotësojnë standardet më të larta dhe nevojat e klientëve.",
      section2Title: "Përkushtimi ndaj Cilësisë",
      section2Description:
        "Dino Qeramika e vendos cilësinë si prioritet kryesor në çdo aspekt të operacioneve të saj. Ne investojmë vazhdimisht në teknologji moderne dhe materiale të avancuara për të siguruar që produktet tona përmbushin standardet më të larta të industrisë. Çdo produkt kalon nëpër procese të rrepta kontrolli për të garantuar që klientët tanë marrin vetëm më të mirën. Kjo filozofi e fokusuar në cilësi ndihmon në ruajtjen e besimit të klientëve dhe në forcimin e reputacionit tonë në tregun e ndërtimit dhe renovimit.",
      section3Title: "Partneritete Ndërkombëtare",
      section3Description:
        "Një faktor kyç në angazhimin tonë ndaj cilësisë është lidhja e fortë me disa kompani të njohura ndërkombëtare, përfshirë:",
        partnerList: [
          { name: "AZUVI SPANJË", description: "Azuvi Keramike ofron pllaka të cilësisë së lartë me dizajne moderne dhe elegante, të përshtatshme për çdo hapësirë banimi apo komerciale." },
          { name: "SERAMIKSAN TURQI", description: "Siguron produkte të cilësisë së lartë që përmbushin nevojat e ndryshme të tregut." },
          { name: "ECO SPANJË", description: "Eco Cerámica Spanjë prodhon pllaka qeramike cilësore, me dizajne moderne dhe fokus në inovacion." },
          { name: "MIRAGE ITALI", description: "E njohur për stilin e saj modern dhe inovativ, kjo kompani kontribuon në diversifikimin e portofolit tonë të produkteve." },
          { name: "LINEART TURQI", description: "Lineart Banyo Ceramic ofron zgjidhje moderne dhe estetike për banjo, duke kombinuar cilësinë me dizajnin inovativ." },
          { name: "CERRAD POLONI", description: "Ofron një gamë të gjerë produktesh, duke i mundësuar Dino Qeramika të ofrojë zgjidhje të përshtatatura për projekte të ndryshme ndërtimore." },
          { name: "ETILI TURQI", description: "Një kompani e njohur në industrinë e qeramikës, e cila ofron produkte dhe zgjidhje të cilësisë së lartë, duke kontribuar në pasurimin e portofolit tonë me materiale estetikisht të këndshme dhe të qëndrueshme." },
          { name: "KERAKOLL ITALI", description: "Kerakoll ofron materiale ndërtimi inovative dhe miqësore me mjedisin, duke u specializuar në zgjidhje që promovojnë qëndrueshmërinë dhe performancën e lartë." },
          { name: "MOSSCA QERAMIKË", description: "Mossaica Ceramics ofron një koleksion unik pllakat dekorative dhe artistike, duke përzierë traditën me modernitetin për dizajne të jashtëzakonshme." },
      ],      
      contact: {
        title: "Vizitoni Showroomin Tonë",
        subtitle: "Ekspertë në Zgjidhje, Partnerë në Sukses",
        addressHeading: "Adresa Jonë",
        address: "14000, Mgj. Prishtinë - Ferizaj",
        workingHours: {
          title: "Orari i Punes",
          mondayToFriday: "Të Hënën - Premten: 8am - 6pm",
          saturday: "Të Shtunen : 9am - 5pm",
          sunday: "Të Dielen : Mbyllur",
        },
        contact: {
          title: "Kontakti",
          email: "Email: info@dino-company.com",
          phone: "Telefoni: 038 566 666",
        },
      },
    }
  };
  
  const activeTranslations = dinoQeramikaTranslations[activeLanguage];
  const logoImages = [
    "assets/seramiksan.png",
    "assets/cerrad.png",
    "assets/Mirage.png",
    "assets/etili.png",
    "assets/SILCERAMICHE.png",
    "assets/raimondi.png",
    "assets/kerakoll.png"
  ];

  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const { title, subtitle, addressHeading, address, workingHours, contact } =
  dinoQeramikaTranslations[activeLanguage].contact;


  return (
    <div className="container mx-auto px-6 py-12">
      {/* Main Content */}
      <div className="relative mb-12 bg-gray-900 rounded-lg overflow-hidden shadow-lg">
        <div className="absolute inset-0 bg-cover" style={{ backgroundImage: "url('assets/mirage04.jpg')" }} />
        <div className="absolute inset-0 bg-gradient-to-t from-red-900 to-transparent"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-64 text-center p-6">
          <h1 data-aos="fade-up" className="text-5xl md:text-6xl font-extrabold text-white mb-4 leading-tight">
            {activeTranslations.header}
          </h1>
          <p data-aos="fade-up" data-aos-delay="200" className="text-lg md:text-xl text-gray-200 max-w-4xl mx-auto">
            {activeTranslations.subHeader}
          </p>
        </div>
      </div>

      {/* Other Sections */}
      <section className="space-y-16">
        {/* Section 1 */}
        <div className="relative grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8">
          <div data-aos="fade-right" className="bg-white p-8 rounded-lg shadow-lg flex flex-col justify-center items-center">
            <h2 className="text-3xl font-semibold text-red-800 mb-4 text-center">
              {activeTranslations.section1Title}
            </h2>
            <p className="text-lg leading-relaxed text-gray-700 text-center">
              {activeTranslations.section1Description}
            </p>
          </div>
          <div data-aos="fade-left" className="relative">
            <img src="assets/mirage02.jpg" alt="How We Work" className="w-full h-auto rounded-lg shadow-md" />
            <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
          </div>
        </div>

        {/* Section 2 */}
        <div className="relative grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8">
          <div data-aos="fade-right" className="relative">
            <img src="assets/mirage01.jpg" alt="Our Approach" className="w-full h-auto rounded-lg shadow-md" />
            <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
          </div>
          <div data-aos="fade-left" className="bg-white p-8 rounded-lg shadow-lg flex flex-col justify-center items-center">
            <h2 className="text-3xl font-semibold text-red-800 mb-4 text-center">
              {activeTranslations.section2Title}
            </h2>
            <p className="text-lg leading-relaxed text-gray-700 text-center">
              {activeTranslations.section2Description}
            </p>
          </div>
        </div>
      </section>
      <section>
      <div data-aos="fade-up" className="mt-16 p-6 rounded-lg shadow-md">
        <h2 className="text-4xl font-extrabold text-red-800 mb-4 text-center">
          {activeTranslations.section3Title}
        </h2>
        <p className="text-lg leading-relaxed text-gray-700 text-center mb-6">
          {activeTranslations.section3Description}
        </p>
        <ul className="list-disc list-inside text-left text-gray-700 mt-4 space-y-2">
          {activeTranslations.partnerList.map((partner, index) => (
            <li key={index}>
              <strong className="text-red-800">{partner.name}:</strong> {partner.description}
            </li>
          ))}
        </ul>
      </div>
      </section>
      
      <div data-aos="fade-up" className="mt-16">
        
        <MarqueeItemSmall images={logoImages} />
      </div>

      {/* Contact Us Section */}
      <section className="mt-14">
        <div className="max-w-7xl mx-auto border-t-2 border-t-red-800 py-20 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-red-800">{title}</h2>
            <p className="mt-4 text-lg text-gray-500">{subtitle}</p>
          </div>

          <div className="mt-16 lg:mt-20">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div
                className="rounded-lg overflow-hidden p-6 shadow-2xl relative"
                style={{
                  boxShadow:
                    "0 10px 20px rgba(0, 0, 0, 0.3), 0 6px 6px rgba(0, 0, 0, 0.2)",
                }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2939.318445573349!2d21.145622976220523!3d42.54853062317342!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1354832f28909bbd%3A0xea152dccbc7aa98a!2sDino%20Company!5e0!3m2!1sen!2s!4v1725284354612!5m2!1sen!2s"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Dino Company Location"
                ></iframe>
              </div>
  
              <div>
                <div className="max-w-full mx-auto rounded-lg overflow-hidden">
                  <div className="px-6 py-4">
                    <h3 className="text-lg font-medium text-red-800">
                      {addressHeading}
                    </h3>
                    <p className="mt-1 text-gray-500">
                      <a
                        href="https://www.google.com/maps/search/?q=Dino+Company,+Lipjan"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-500 hover:text-red-800 decoration-none hover:underline"
                      >
                        {address}
                      </a>
                    </p>
                  </div>
                  <div className="border-t border-red-800 px-6 py-4">
                    <h3 className="text-lg font-medium text-red-800">
                      {workingHours.title}
                    </h3>
                    <p className="mt-1 text-gray-500">
                      {workingHours.mondayToFriday}
                    </p>
                    <p className="mt-1 text-gray-500">{workingHours.saturday}</p>
                    <p className="mt-1 text-gray-500">{workingHours.sunday}</p>
                  </div>
                  <div className="border-t border-red-800 px-6 py-4">
                    <h3 className="text-lg font-medium text-red-800">
                      {contact.title}
                    </h3>
                    <p className="mt-1 text-gray-500">{contact.email}</p>
                    <p className="mt-1 text-gray-500">+383 44 59 59 59</p>
                    <p className="mt-1 text-gray-500">+383 48 497 777</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section data-aos="fade-up">
        <Marquee />
      </section>
    </div> 
     );
};
export default DinoQeramika;