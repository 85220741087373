import React from "react";
import Marquee from "../components/Marquee";
import Cards from "../components/Cards";
import NjesiteItems from "../components/NjesiteItems";
import WhyUs from "../components/WhyUs";
import Carousel from "../components/Carousel";
import NewsAboutDino from "../components/NewsAboutDino";
import ContactUs from "../components/ContactUs";
import Counter from "../components/Counter"; 

function Home() {
  return (
    <div className="bg-slate-50">
      <div>
        <Carousel />
      </div>
      <div>
        <NjesiteItems />
      </div>
      <div>
        <Cards />
      </div>
      <div>
        <WhyUs />
      </div>
      <div>
        <Counter /> 
      </div>
      <div>
        <NewsAboutDino />
      </div>
      <div>
        <ContactUs />
      </div>
      <div>
        <Marquee />
      </div>
    </div>
  );
}

export default Home;
