import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LanguagePicker from "../components/language/LanguagePicker";
import { useSelector } from "react-redux";

function Header() {
  const [scrolling, setScrolling] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const language = useSelector((state) => state.language);
  const activeLanguage = language.language;
  const translations = language.translations;

  const handleScroll = () => {
    setScrolling(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setActiveDropdown(null);
  }, [location.pathname]);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  const handleDropdownToggle = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleMenuItemClick = (link) => {
    setMobileMenuOpen(false); 
    setTimeout(() => {
      navigate(link);
    }, 300);
  };

  const handleSubItemClick = (link) => {
    setActiveDropdown(null); 
    setMobileMenuOpen(false); 
    setTimeout(() => {
      navigate(link);
    }, 300);
  };

  const headerItems = [
    { name: translations[activeLanguage]?.header?.home || "Home", link: "/" },
    {
      name: translations[activeLanguage]?.header?.aboutUs || "About us",
      link: "/about",
    },
    {
      name: translations[activeLanguage]?.header?.units || "UNITS",
      subItems: [
        {
          name:
            translations[activeLanguage]?.header?.construction ||
            "DINO CONSTRUCTION",
          link: "/dino-ndertimi",
        },
        {
          name: translations[activeLanguage]?.header?.ceramic || "DINO CERAMIC",
          link: "/dino-qeramika",
        },
        {
          name:
            translations[activeLanguage]?.header?.gurethyes ||
            "DINO STONE-CRUSHER",
          link: "/dino-gurethyes",
        },
        {
          name: translations[activeLanguage]?.header?.beton || "DINO CONCRETE",
          link: "/dino-beton",
        },
        {
          name: translations[activeLanguage]?.header?.asfalt || "DINO ASPHALT",
          link: "/dino-asfalt",
        },
      ],
    },
    {
      name: translations[activeLanguage]?.header?.news || "NEWS",
      link: "/news",
    },
    {
      name: translations[activeLanguage]?.header?.contact || "CONTACT",
      link: "/contact",
    },
  ];

  return (
    <header
      className={`font-poppins z-50 fixed top-0 w-full flex items-center py-2 px-4 font-abcd h-[75px] ${
        scrolling ? "bg-white bg-opacity-40" : ""
      }`}
    >
      <div className="container mx-auto flex items-center justify-between h-full">
        <Link to="/">
          <img src="/assets/logo.png" alt="logo" width={150} />
        </Link>

        <nav className="hidden lg:flex flex-grow items-center justify-center relative">
          <ul className="flex items-center space-x-10 lg:space-x-16 xl:space-x-20 m-0">
            {headerItems.map((data, index) => {
              if (data.subItems) {
                return (
                  <li key={`header-item-${index}`} className="relative flex items-center">
                    <button
                      onClick={() => handleDropdownToggle(index)}
                      className="flex items-center text-gray-700 no-underline hover:text-red-700"
                    >
                      {data.name}
                      <svg
                        className={`w-4 h-4 ml-2 ${
                          activeDropdown === index ? "rotate-180" : "rotate-0"
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M6 9l6 6 6-6" />
                      </svg>
                    </button>
                    {activeDropdown === index && (
                      <ul className="absolute left-0 w-48 bg-white border mt-72 border-gray-200 shadow-lg rounded-lg p-0">
                        {data.subItems.map((subItem, subIndex) => (
                          <li
                            key={`sub-item-${subIndex}`}
                            className="border-b border-gray-200 last:border-b-0"
                          >
                            <Link
                              to={subItem.link}
                              onClick={() => handleSubItemClick(subItem.link)}
                              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 no-underline"
                            >
                              {subItem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              }
              return (
                <li key={`header-item-${index}`} className="text-center border-b border-gray-200 md:border-b-0 last:border-b-0">
                  <Link
                    to={data.link}
                    className="block text-gray-700 no-underline hover:text-red-700"
                  >
                    {data.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="flex items-center space-x-4 md:space-x-6 lg:space-x-8">
          <LanguagePicker />
          <button
            onClick={handleMobileMenuToggle}
            className="lg:hidden p-2 rounded-full"
            aria-label="Toggle Menu"
          >
            <svg
              className="w-6 h-6 text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="fixed top-0 right-0 h-full max-w-fit bg-customRed bg-opacity-80 z-50 lg:hidden">
          <div className="flex justify-between items-center p-4">
            <button
              onClick={handleMobileMenuToggle}
              className="p-2 rounded-full"
              aria-label="Close Menu"
            >
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <nav className="p-4">
            <ul className="space-y-4 text-left">
              {headerItems.map((data, index) => {
                if (data.subItems) {
                  return (
                    <li key={`header-item-${index}`}>
                      <button
                        onClick={() => handleDropdownToggle(index)}
                        className="flex pl-6 items-center text-white no-underline hover:text-gray-300"
                      >
                        {data.name}
                        <svg
                          className={`w-4 h-4 ml-2 ${
                            activeDropdown === index ? "rotate-180" : "rotate-0"
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </button>
                      {activeDropdown === index && (
                        <ul className="ml-2 space-y-2">
                          {data.subItems.map((subItem, subIndex) => (
                            <li key={`sub-item-${subIndex}`}>
                              <Link
                                to={subItem.link}
                                onClick={() => handleSubItemClick(subItem.link)}
                                className="block text-white hover:bg-red-700 no-underline px-4 py-2"
                              >
                                {subItem.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                }
                return (
                  <li key={`header-item-${index}`}>
                    <Link
                      to={data.link}
                      onClick={() => handleMenuItemClick(data.link)}
                      className="block text-white no-underline hover:text-gray-300 px-4 py-2"
                    >
                      {data.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;
