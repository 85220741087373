import React from "react";
import MarqueeItem from "./MarqueeItem";

const Marquee = () => {
  const upperMarquee = [
    "/assets/brezoviaHotelLogo.jpg",
    "/assets/dinoQeramikaLogo.jpeg",
    "/assets/dinoNdertimiLogo.jpeg",
    "/assets/dinoBetonLogo.jpeg",
    "/assets/dinoGurthyesLogo.jpeg",
    "/assets/dinoAsfaltLogo.jpeg",
    "/assets/greenParkLogo.jpeg",
    "/assets/hotelGermiaLogo.jpeg",
    "/assets/dinoNdertimiLogo.jpeg",
    "/assets/dinoQeramikaLogo.jpeg",
    "/assets/dinoBetonLogo.jpeg",
    "/assets/dinoGurthyesLogo.jpeg",
    "/assets/dinoAsfaltLogo.jpeg",
    "/assets/brezoviaHotelLogo.jpg",
    "/assets/greenParkLogo.jpeg",
    "/assets/hotelGermiaLogo.jpeg",
  ];

  return (
    <div className="relative overflow-hidden bg-white">
      <MarqueeItem images={upperMarquee} />
    </div>
  );
};

export default Marquee;
