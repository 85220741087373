import { createSlice } from "@reduxjs/toolkit";
import Translations from "../language/Translations";

const initialState = {
    language: "sq", // Default language
    translations: Translations,
};

const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.language = action.payload; // Change language
        },
    },
});

export const { changeLanguage } = languageSlice.actions;
export default languageSlice.reducer;
