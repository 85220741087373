import React from "react";
import { motion } from "framer-motion";

const MarqueeItem = ({ images }) => {
  const imageWidth = 120; 
  const imageSpacing = 10;
  const totalWidth = imageWidth + imageSpacing;

  const imageElements = [...images, ...images];

  return (
    <div className="relative overflow-hidden w-full">
      <div className="flex">
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: `-${totalWidth * images.length}px` }}
          transition={{
            duration: 90,  
            repeat: Infinity,
            ease: "linear", 
          }}
          className="flex"
        >
          {imageElements.map((image, index) => (
            <img
              className="h-16 w-32 md:h-20 md:w-40 pr-2 md:pr-4" 
              src={image}
              alt={`Marquee item ${index}`}
              key={index}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default MarqueeItem;
