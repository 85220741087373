import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NjesiteItems = () => {
  const { language, translations } = useSelector((state) => state.language);
  const activeLanguage = language || "sq";

  const items = [
    {
      imgSrc: "/assets/qeramika.png",
      altKey: "dinoQeramika",
      link: "/dino-qeramika",
    },
    {
      imgSrc: "/assets/ndertimi1.png",
      altKey: "dinoNdertime",
      link: "/dino-ndertimi",
    },
    { imgSrc: "/assets/betoni.png", altKey: "dinoBeton", link: "/dino-beton" },
    {
      imgSrc: "/assets/gurethyes.png",
      altKey: "dinoGurethyes",
      link: "/dino-gurethyes",
    },
    {
      imgSrc: "/assets/asphalt.png",
      altKey: "dinoAsfalt",
      link: "/dino-asfalt",
      width: "60",
    },
  ];

  return (
    <div className="mt-14 px-4 md:px-0">
      <div className="flex justify-center">
        <hr className="w-full md:w-11/12 border-red-800" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 items-center justify-items-center mt-6">
        {items.map(({ imgSrc, altKey, link }) => {
          const translation = translations[activeLanguage]?.njesite?.[altKey];

          return (
            <div className="text-center" key={altKey}>
              <Link to={link}>
                <img
                  src={imgSrc}
                  alt={translation || "Image"}
                  className="mx-auto mb-2 w-8 h-8 text-sha md:w-10 md:h-10 object-contain"
                />
              </Link>
              <Link to={link} className="no-underline text-gray-700">
                {translation ? (
                  <p className="text-xs md:text-sm font-medium">
                    {translation}
                  </p>
                ) : (
                  <p className="text-xs md:text-sm font-medium">
                    Content Not Available
                  </p>
                )}
              </Link>
            </div>
          );
        })}
      </div>

      <div className="flex justify-center mt-6">
        <hr className="w-full md:w-11/12 border-t border-red-800" />
      </div>
    </div>
  );
};

export default NjesiteItems;
