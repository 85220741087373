// import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";

const WhyUs = () => {

	const language = useSelector((state) => state.language);
	const activeLanguage = language.language || "en";
	const translations = language.translations || {};
	const whyUsTranslations =
		translations[activeLanguage]?.whyUsTranslations || {};

	const buttonTranslations = {
		en: "Read More",
		sq: "Më shumë",
	};

	const readMore = buttonTranslations[activeLanguage] || buttonTranslations.en;

	return (
		<>
			<div
				className="w-[90%] h-1 bg-red-800 mx-auto mt-7"
				data-aos="fade-right"
				data-aos-duration="1000"
				data-aos-easing="ease-in-out"
			></div>

			<section className="w-full h-screen flex items-center bg-gray-50">
				<div
					className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-10 flex flex-col md:flex-row items-center justify-between space-y-8 md:space-y-0"
					data-aos="fade-right"
				>
					<div className="w-full md:w-1/2 text-center md:text-left space-y-6">
						<h1 className="text-4xl md:text-5xl font-bold text-red-800 leading-tight">
							{whyUsTranslations.whoAreWeTitle || "Who Are We?"}
						</h1>
						<p className="text-lg md:text-xl text-gray-700">
							{whyUsTranslations.whoAreWeText1 ||
								"Dino Company specializes in high-quality construction projects for both commercial and residential needs, delivering innovative and reliable solutions."}
						</p>
						<Link
							to="/About"
							className="inline-block text-red-700 py-2 px-4 rounded hover:bg-red-700 hover:text-white transition duration-200 no-underline text-center"
						>
							{readMore}
						</Link>
					</div>

					<div className="w-full md:w-1/2 overflow-hidden rounded-lg shadow-2xl">
						<img
							src="/assets/whoAreWe.jpg"
							alt={whyUsTranslations.whoAreWeTitle || "Who Are We?"}
							className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
						/>
					</div>
				</div>
			</section>
		</>
	);
};

export default WhyUs;
