import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ContactUs from "../components/ContactUs";
import Marquee from "../components/Marquee";
import AOS from 'aos';
import 'aos/dist/aos.css';

const DinoAsfalt = () => {
  const language = useSelector((state) => state.language);
  const activeLanguage = language.language || "en";
  const translations = language.translations || {};

  const dinoAsfaltTranslations = translations[activeLanguage]?.dinoAsfaltTranslations || {
    en: {
      header: "DINO ASPHALT",
      subHeader: "Excellent Solutions for Infrastructure Development",
      section1Title: "DINO ASPHALT",
      section1Description:
        "DINO Asphalt is a leader in the production and supply of asphalt materials for road and infrastructure construction. With a skilled team and advanced technology, the company offers high-quality products to meet the needs of the most complex projects.",
      section1Description2:
        "DINO Asphalt is committed to using environmentally friendly materials and implementing sustainable practices at every stage of the production process. The production capacity is designed to meet market demands, ensuring that each project is completed efficiently and in compliance with international standards.",
      section2Title: "Quality and Commitment",
      section2Description:
        "DINO Asphalt is committed to providing innovative and reliable solutions, contributing to the construction of a strong and sustainable infrastructure in Kosovo. We work closely with our clients to ensure that every project achieves the desired success, offering support and expertise throughout the entire process.",
      section2Description2:
        "Every product we deliver is tested and verified to ensure maximum quality and durability. DINO Asfalt is your trusted partner in every construction project!"
    },
    sq: {
      header: "DINO ASFALT",
      subHeader: "Zgjidhje të shkëlqyera për ndërtimin e infrastrukturës",
      section1Title: "DINO ASFALT",
      section1Description:
        "DINO Asfalt është lider në prodhimin dhe furnizimin e materialeve asfaltike për ndërtimin e rrugëve dhe infrastrukturës. Me një ekip të kualifikuar dhe teknologji të avancuar, kompania ofron produkte të cilësisë së lartë për të përmbushur nevojat e projekteve më të ndërlikuara.",
      section1Description2:
        "DINO Asfalt angazhohet për përdorimin e materialeve miqësore me ambientin dhe implementimin e praktikave të qëndrueshme në çdo fazë të procesit të prodhimit. Kapaciteti i prodhimit është projektuar për të përballuar kërkesat e tregut, duke garantuar që çdo projekt të realizohet me efikasitet dhe në përputhje me standardet ndërkombëtare.",
      section2Title: "Cilësia dhe Përkushtimi",
      section2Description:
        "DINO Asfalt është e angazhuar për të ofruar zgjidhje inovative dhe të besueshme, duke kontribuar në ndërtimin e një infrastrukture të fortë dhe të qëndrueshme në Kosovë. Ne punojmë ngushtë me klientët tanë për të siguruar që çdo projekt të arrijë suksesin e dëshiruar, duke ofruar mbështetje dhe ekspertizë gjatë tërë procesit.",
      section2Description2:
        "Çdo produkt që dërgojmë është testuar dhe verifikuar për të garantuar cilësinë maksimale dhe qëndrueshmëri. DINO Asfalt është partneri juaj i besueshëm në çdo projekt ndërtimi!"
    }
  };

  const activeTranslations = dinoAsfaltTranslations[activeLanguage];

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="container mx-auto px-6 py-12">
      <div className="relative mb-12 bg-gray-900 rounded-lg overflow-hidden shadow-2xl">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: "url('assets/12.jpg')" }}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-red-950 to-transparent"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-64 text-center p-6">
          <h1 data-aos="fade-up" className="text-5xl md:text-6xl font-extrabold text-white mb-4 leading-tight">
            {activeTranslations.header}
          </h1>
          <p data-aos="fade-up" data-aos-delay="200" className="text-lg md:text-xl text-gray-200 max-w-4xl mx-auto">
            {activeTranslations.subHeader}
          </p>
        </div>
      </div>
      <section className="space-y-16">
  <div className="relative grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8">

    <div data-aos="fade-left" className="bg-white p-8 rounded-lg shadow-lg flex flex-col justify-center items-center">
      <h2 className="text-3xl font-semibold text-red-800 mb-4 text-center">
        {activeTranslations.section1Title}
      </h2>
      <p className="text-lg leading-relaxed text-gray-700 text-center">
        {activeTranslations.section1Description}
      </p>
      <p className="text-lg leading-relaxed text-gray-700 text-center mt-4">
        {activeTranslations.section1Description2}
      </p>
    </div>
    <div data-aos="fade-right" className="relative overflow-hidden">
  <img
    src="assets/01.jpg"
    alt="DINO Asfalt Overview"
    className="w-full sm:h-[300px] md:h-[350px] lg:h-auto object-cover object-center"
  />
</div>


  </div>

  <section data-aos="fade-up">
    <div className="md:w-full bg-white p-8 rounded-lg shadow-2xl transform hover:translate-y-2 transition-transform duration-300 ease-in-out">
      <h2 className="text-3xl md:text-4xl font-extrabold text-red-700 mb-6">
        {activeTranslations.section2Title}
      </h2>
      <p className="text-lg leading-relaxed mb-6 text-gray-700">
        {activeTranslations.section2Description}
      </p>
      <p className="text-lg leading-relaxed mb-6 text-gray-700">
        {activeTranslations.section2Description2}
      </p>
    </div>
  </section>
</section>


      <section data-aos="fade-up">
        <ContactUs />
      </section>

      <section data-aos="fade-up">
        <Marquee />
      </section>
    </div>
  );
};

export default DinoAsfalt;
