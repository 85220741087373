import React from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import newsData from "../components/newsData";
import Translations from "../redux/language/Translations";

const NewsDetail = () => {
	const { id } = useParams();
	const article = newsData.find((article) => article.id === parseInt(id));

	const language = useSelector((state) => state.language);
	const activeLanguage = language.language || "sq";

	const detailedContent = {
		5: {
			title: {
				en: "Dino Residence",
				sq: "Dino Residence",
			},
			paragraphs: [
				{
					en: "We are excited to announce the upcoming construction of a new, modern building in Maja e Veternikut, one of the most beautiful and sought-after areas in Pristina. This project offers excellent living conditions, with stunning views and a convenient location close to essential city services and infrastructure.",
					sq: "Jemi të lumtur të njoftojmë nisjen e ndërtimit të një objekti të ri dhe modern në Majën e Vetërnikut, një nga zonat më të bukura dhe më të kërkuara të Prishtinës. Ky projekt ofron kushte të shkëlqyera jetese, me pamje të mrekullueshme dhe një lokacion të përshtatshëm, pranë shërbimeve dhe infrastrukturës kryesore të qytetit.",
				},
				{
					en: "The building will be constructed according to the highest standards of safety and quality, using durable materials and modern technology. Each apartment will offer functional spaces with natural light and breathtaking views.",
					sq: "Objekti do të ndërtohet sipas standardeve më të larta të sigurisë dhe cilësisë, duke përdorur materiale të qëndrueshme dhe teknologji moderne. Çdo apartament do të ofrojë hapësira funksionale, me dritë natyrale dhe pamje të jashtëzakonshme.",
				},
				{
					en: "This project aims to create a safe and comfortable environment for those seeking a modern home with direct access to the city center, while also enjoying the tranquility of the neighborhood. Construction will be carried out in full compliance with the approved building permit and is expected to be completed in a short time frame.",
					sq: "Ky projekt synon të krijojë një mjedis të sigurt dhe të rehatshëm për ata që kërkojnë një shtëpi moderne, me lidhje të drejtpërdrejta me qendrën e qytetit, por edhe me qetësinë që ofron lagjja. Ndërtimi do të realizohet në përputhje me lejen e ndërtimit të miratuar nga autoritetet dhe pritet të përfundojë në një periudhë të shkurtër kohore.",
				},
				{
					en: "For those looking to make an excellent choice for their home, Dino Residence offers the opportunity to create a new, comfortable life in one of the most desirable locations in Pristina.",
					sq: "Për ata që dëshirojnë të bëjnë një zgjedhje të shkëlqyer për shtëpinë e tyre, Dino Residence ofron mundësinë për të krijuar një jetë të re dhe të rehatshme në një prej lokacioneve më të kërkuara të Prishtinës.",
				},
			],
		},
		1: {
			title: {
				en: "Transformation of the Pristina Ring Road:",
				sq: "Transformimi i Rrugës së Unazës në Prishtinë:",
			},
			paragraphs: [
				{
					en: "The works on the Pristina Ring have started to take on a completely different form thanks to the contribution of Dino Company. With deep commitment and consolidated expertise in construction, this company has played a key role in transforming the project.",
					sq: "Punimet tek Unaza e Prishtinës kanë filluar të marrin një formë krejtësisht tjetër falë kontributit të kompanisë Dino Company. Me një përkushtim të thellë dhe ekspertizë të konsoliduar në fushën e ndërtimit, kjo kompani ka luajtur një rol kyç në transformimin e projektit.",
				},
				{
					en: "Dino Company has brought visible improvements in the quality of construction and in the realization of various phases of work, making the project one of the most important for the road infrastructure of the capital. Their interventions have directly impacted the improvement of vehicle circulation, increased safety, and improved traffic conditions for the citizens of Pristina.",
					sq: "Dino Company ka sjellë përmirësime të dukshme në cilësinë e ndërtimit dhe në realizimin e fazave të ndryshme të punimeve, duke e bërë projektin një ndër më të rëndësishmit për infrastrukturën rrugore të kryeqytetit. Ndërhyrjet e tyre kanë ndikuar drejtpërdrejt në përmirësimin e qarkullimit të automjeteve, rritjen e sigurisë dhe përmirësimin e kushteve të qarkullimit për qytetarët e Prishtinës.",
				},
				{
					en: "The complexity of the project and the high quality standards followed by Dino Company have had a significant impact on transforming the urban landscape and improving infrastructure, providing sustainable solutions for a continuously developing city.",
					sq: "Kompleksiteti i projektit dhe standardet e larta të cilësisë të ndjekura nga Dino Company kanë pasur një ndikim të madh në transformimin e pamjes urbane dhe përmirësimin e infrastrukturës, duke ofruar zgjidhje të qëndrueshme për një qytet në zhvillim të vazhdueshëm.",
				},
			],
		},
		3: {
			title: {
				en: "Pristina-Gjilan Road Project:",
				sq: "Projekti i Rrugës Prishtinë-Gjilan:",
			},
			paragraphs: [
				{
					en: "After tireless work and continuous effort, the Pristina - Gjilan road is almost complete. This project, one of the most important regional connecting arteries, has gone through several complex construction phases, where dedication and professionalism have been essential for its success.",
					sq: "Pas një pune të palodhur dhe një përpjekje të vazhdueshme, rruga Prishtinë - Gjilan është pothuajse në përfundim. Ky projekt, një nga arteriet më të rëndësishme të lidhjes rajonale, ka kaluar nëpër disa faza të ndërlikuara ndërtimi, ku përkushtimi dhe profesionalizmi kanë qenë thelbësore për suksesin e tij.",
				},
				{
					en: "Dino Company's contribution to this project has brought significant improvements in construction quality and road safety. Thanks to their expertise, the road is expected to provide high conditions for circulation, improving mobility and making transport safer and more efficient for the citizens of both cities.",
					sq: "Kontributi i kompanisë Dino Company në këtë projekt ka sjellë përmirësime të rëndësishme në cilësinë e ndërtimit dhe sigurinë e rrugës. Falë ekspertizës së tyre, rruga pritet të ofrojë kushte të larta për qarkullim, duke përmirësuar lëvizjen dhe duke e bërë transportin më të sigurt dhe më efikas për qytetarët e të dy qyteteve.",
				},
				{
					en: "The new road will play a key role in connecting Pristina and Gjilan, improving regional infrastructure and opening new opportunities for economic and social development.",
					sq: "Rruga e re do të luajë një rol kyç në lidhjen mes Prishtinës dhe Gjilanit, duke përmirësuar infrastrukturën rajonale dhe duke hapur mundësi të reja për zhvillim ekonomik e social.",
				},
			],
		},
		4: {
			title: {
				en: "Brezovica Hotel & SPA",
				sq: "Brezovica Hotel & SPA:",
			},
			paragraphs: [
				{
					en: "Among a series of projects completed by Dino Ceramics, Brezovica Hotel & SPA stands out as an extraordinary example of commitment to every detail. This project is special not only for its attractive aesthetic design but also for its maximum focus on quality, reflecting the highest standards in every aspect.",
					sq: "Në mesin e një sërë projektesh të realizuara nga Dino Qeramika, Brezovica Hotel & SPA shquhet si një shembull i jashtëzakonshëm për përkushtimin në çdo detaj. Ky projekt është i veçantë jo vetëm për dizajnin estetik tërheqës, por edhe për përqendrimin maksimal në cilësi, duke reflektuar standardet më të larta në çdo aspekt të tij.",
				},
				{
					en: "From elegantly designed swimming pools, expertly crafted wall decorations, to restrooms and every other element that includes ceramics, Dino Ceramics has ensured that every detail is contained and suitable for a luxurious environment like Brezovica Hotel & SPA.",
					sq: "Nga pishinat e dizajnuara me qeramikë elegante, dekorimet e mureve të realizuara me mjeshtëri, deri tek tualetet dhe çdo element tjetër që përfshin qeramikën, Dino Qeramika ka siguruar që çdo detaj të jetë i përmbajtur dhe i përshtatshëm për një ambient luksoz si Brezovica Hotel & SPA.",
				},
				{
					en: "This project represents a precise combination of aesthetics and functionality, creating beautiful and comfortable spaces for clients. The work done on this project is a testament to our dedicated commitment to achieving the highest quality and presenting appealing solutions for our clients. Dino Ceramics continues to strengthen its reputation as a leader in the design and implementation of high-standard projects in the construction and interior sector.",
					sq: "Ky projekt përfaqëson një kombinim të përpiktë midis estetikës dhe funksionalitetit, duke krijuar hapësira të bukura dhe komode për klientët. Puna e realizuar në këtë projekt është një dëshmi e angazhimit tonë të përkushtuar për të arritur cilësinë më të lartë dhe për të prezantuar zgjidhje tërheqëse për klientët tanë. Dino Qeramika vazhdon të përforcojë reputacionin e saj si një lider në dizajnin dhe implementimin e projekteve të standardeve të larta në sektorin e ndërtimit dhe interierit.",
				},
			],
		},
		2: {
			title: {
				en: "Sports Hall in Lipjan:",
				sq: "Salla Sportive në Lipjan:",
			},
			paragraphs: [
				{
					en: "The sports hall in Lipjan, completed a few months ago, offers a suitable environment for various sports activities. With the arrival of spring, the citizens of Lipjan have the opportunity to benefit from the infrastructure of this hall for exercising and promoting healthy training.",
					sq: "Salla e sportit në Lipjan, e përfunduar disa muaj më parë, ofron një ambient të përshtatshëm për aktivitete sportive të ndryshme. Me ardhjen e pranverës, qytetarët e Lipjanit kanë mundësinë të përfitojnë nga infrastruktura e kësaj salle për të ushtruar dhe për të promovuar stërvitjen e shëndetshme.",
				},
				{
					en: "This hall is a valuable resource for the community, providing a safe and pleasant space for sports activities at different times of the year. Its construction is an example of investments in local infrastructure that benefit the community.",
					sq: "Kjo sallë është një burim i vlefshëm për komunitetin, duke ofruar një hapësirë të sigurt dhe të këndshme për aktivitete sportive në periudha të ndryshme të vitit. Ndërtimi i saj është një shembull i investimeve në infrastrukturën lokale që ndihmojnë komunitetin.",
				},
				{
					en: "For more information on usage schedules and available activities, citizens can contact the sports hall in Lipjan directly.",
					sq: "Për më shumë informacion mbi oraret e përdorimit dhe aktivitetet e disponueshme, qytetarët mund të kontaktojnë direkt sallën e sportit në Lipjan.",
				},
			],
		},
	};
	

	if (!article) {
		return <div className="text-center text-red-600">{Translations[activeLanguage]?.newsTranslationsButton?.articleNotFound || "Article not found."}</div>;
	}

	const activeContent = detailedContent[id];

	return (
		<div className="container mx-auto px-6 py-12">
			<div className="relative mb-12 bg-gray-800 rounded-lg overflow-hidden shadow-lg">
				<div
					className="absolute inset-0 bg-cover bg-center"
					style={{ backgroundImage: `url(${article.image})` }} 
				/>
				<div className="absolute inset-0 bg-black opacity-50"></div>
				<div className="relative z-10 flex flex-col items-center justify-center h-64 text-center p-6">
					<h1 className="text-xl md:text-4xl font-bold text-white mb-4 leading-tight">
						{article.title}
					</h1>
				</div>
			</div>
			<div className="mt-6 mb-10">
				<Link
					to="/news"
					className="inline-block  text-red-700 py-2 px-4 rounded hover:bg-red-700 hover:text-white transition duration-200 no-underline"
				>
					{Translations[activeLanguage]?.newsTranslationsButton?.backToNews || "Kthehu te Lajmet"}
				</Link>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
				<div>
					{activeContent ? (
						<>
							<h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-4">
								{activeContent.title[activeLanguage]}
							</h2>
							{activeContent.paragraphs.map((paragraph, index) => (
								<p key={index} className="text-gray-600 mb-4">
									{paragraph[activeLanguage]}
								</p>
							))}
						</>
					) : (
						<p className="text-gray-600">{article.content}</p>
					)}
				</div>

				<div className="flex items-center justify-center">
					<div className="relative w-3/4">
						<div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
						<img
							src={article.image}
							alt={article.title}
							className="w-full h-auto rounded-lg shadow-lg object-cover" 
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsDetail;
