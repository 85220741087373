const Translations = {
	en: {
		header: {
			home: "Home",
			aboutUs: "About us",
			units: "Units",
			construction: "Dino Construction",
			ceramic: "Dino Ceramic",
			gurethyes: "Dino Stone-Crusher",
			beton: "Dino Concrete",
			asfalt: "Dino Asphalt",
			news: "News",
			contact: "Contact",
		},
		carousel: {
			welcome: "Experts in Solutions, Partners in Success",
			title: "DINO COMPANY",
		},
		njesite: {
			dinoQeramika: "Dino Ceramics",
			dinoNdertime: "Dino Construction",
			dinoBeton: "Dino Concrete",
			dinoGurethyes: "Dino Stone-Crusher",
			dinoAsfalt: "Dino Asphalt",
		},
		cards: {
			dinoBeton:
				"DINO CONCRETE offers the production of high-quality ready-mixed concrete, meeting the highest standards. With a balanced mix and modern technology, our concrete is ideal for any type of construction, ensuring strength and longevity for your projects.",

			dinoAsfalt:
				"DINO ASPHALT produces high-quality asphalt, suitable for the construction and maintenance of roads and infrastructure. Our asphalt is designed to offer durability and resistance to harsh weather conditions.",

			dinoNdertime:
				"DINO CONSTRUCTION provides complete construction services, from architectural design to final realization. With a team of experienced professionals, we deliver projects completed to the highest standards and technical safety.",

			dinoQeramika:
				"DINO CERAMICS offers a wide range of quality ceramics, suitable for both indoor and outdoor spaces. Our ceramics feature modern designs and are built to last over time.",

			dinoGurethyes:
				"DINO STONE-CRUSHER produces quality construction stones for concrete and large engineering projects. Our products provide a strong and durable foundation for your constructions.",
		},
		whyUsTranslations: {
			whoAreWeTitle: "Who Are We?",
			whoAreWeText1:
				"Dino Company is a leader in construction, specializing in commercial and residential complexes. With a focus on innovation and quality, we have built a reputation for high-quality projects that meet the needs of our clients.",

			whyChooseUsTitle: "Why Choose Dino Company?",
			experienceTitle: "Experience and Professionalism",
			experienceText:
				"With decades of experience, Dino Company has led major projects, from shopping centers to residential developments. Our expert team ensures that every project is executed with care and precision.",

			qualityProductsTitle: "High-Quality Products and Services",
			qualityProductsText:
				"Providing quality building materials such as concrete, asphalt, and stone, designed for durability. We offer complete construction services from planning to final execution.",

			clientCommitmentTitle: "Commitment to Clients",
			clientCommitmentText:
				"Our clients are our priority. We provide customized solutions, ensuring the completion of projects according to specifications, on time, and within budget.",
		},
		newsTranslations: {
			header: "Latest News",
			readMore: "Read More",
		},

		news: [
			{
				title: "Transformation of the Pristina Ring Road:",
				content:
					"The works on the Pristina Ring Road have begun to take on a completely different form thanks to the contribution of Dino Company. With deep commitment and consolidated expertise in the construction field, this company has played a key role in transforming the project.",
				image: "/assets/67.jpg",
			},
			{
				title: "Sports Hall in Lipjan:",
				content:
					"The sports hall in Lipjan, completed a few months ago, offers a suitable environment for various sports activities. With the arrival of spring, the citizens of Lipjan have the opportunity to benefit from the infrastructure of this hall to exercise and promote healthy training.",
				image: "/assets/22.jpg",
			},
			{
				title: "Pristina-Gjilan Road Project:",
				content:
					"After tireless work and continuous effort, the Pristina-Gjilan road is almost complete. This project, one of the most important arteries for regional connectivity, has gone through several complex construction phases, where dedication and professionalism have been essential for its success.",
				image: "/assets/44.jpg",
			},
			{
				title: "Brezovica Hotel & SPA:",
				content:
					"Among a series of projects carried out by Dino Qeramika, Brezovica Hotel & SPA stands out as an exceptional example of commitment to every detail. This project is special not only for its attractive aesthetic design but also for the utmost focus on quality, reflecting the highest standards in every aspect of it.",
				image: "/assets/hoteli.11.jpeg",
			},
			{
				title: "Dino Residence",
				content:
					"We are excited to announce the upcoming construction of a new, modern building in Maja e Veternikut, one of the most beautiful and sought-after areas in Pristina. This project offers excellent living conditions, with stunning views and a convenient location close to essential city services and infrastructure.",
				image: "/assets/veternik.jpeg",
			},
		],
		contact: {
			title: "Visit Our Location",
			subtitle: "Experts in Solutions, Partners in Success",
			addressHeading: "Our Address",
			address: "14000, ST. Prishtina - Ferizaj",
			workingHours: {
				title: "Working Hours",
				mondayToFriday: "Monday - Friday: 8am - 6pm",
				saturday: "Saturday: 9am - 5pm",
				sunday: "Sunday: Closed",
			},
			contact: {
				title: "Contact",
				email: "Email: info@dino-company.com",
				phone: "Phone: 038 566 666",
			},
		},
		aboutTranslations: {
			header: "About Us",
			subHeader:
				"Discover who we are, what drives us, and how we make a difference.",
			historyTitle: "Our History",
			historyText:
				"We have been dedicated to excellence for over 20 years, creating meaningful change and innovation.",
			missionTitle: "Our Mission",
			missionText:
				"Our mission is to provide high-quality services and products that address our clients' needs with professionalism and expertise.",
			visionTitle: "Our Vision",
			visionText:
				"Our vision is to lead with social responsibility and set new standards of excellence.",
			textTitle: "What Sets Us Apart",
			textBody:
				"What sets Dino Company apart is our unwavering commitment to quality and our relentless pursuit of innovation.",
			partnerTitle: "Why Choose Dino Company",
			partnerText:
				"Partnering with Dino Company means choosing reliability, expertise, and a commitment to bringing your vision to life.",
			additionalDetailsTitle: "Additional Details",
			additionalDetailsText:
				"Dino Company also owns Brezovica Hotel & Spa, a luxury destination in the stunning landscape of Brezovica.",
			teamTitle: "Meet Our Team",
		},
		historyTranslations: {
			title: "History and Evolution",
			consolidationTitleHeading: "Consolidation",
			showMore: "Show more ↓",
			showLess: "Show less ↑",
			description:
				"Founded in 2000, Dino Company began as a sole proprietorship with a clear vision: to set new standards in the construction industry by delivering exceptional quality, innovative solutions, and unparalleled dedication to our clients. Over the years, we have grown into a trusted name in construction and premium material supply, earning a reputation as a leader in the field.",
			foundingTitle: "We operate through five specialized branches:",
			foundingPoints: [
				{
					label: "DINO CERAMICS:",
					text: "Supplying the finest ceramics, including exclusive imports from the most renowned Italian manufacturers.",
				},
				{
					label: "DINO CONSTRUCTION:",
					text: "Providing turnkey construction solutions that meet and exceed industry standards.",
				},
				{
					label: "DINO QUARRIES:",
					text: "Experts in producing gravel and aggregates for all construction needs.",
				},
				{
					label: "DINO CONCRETE:",
					text: "Producing high-quality concrete tailored to project specifications, with reliable on-site delivery.",
				},
				{
					label: "DINO ASPHALT:",
					text: "Leaders in the production and precise application of asphalt for roads and infrastructure.",
				},
			],
			expansionTitle:
				"In addition to our extensive construction expertise, we are proud owners of Brezovica Hotel & Spa, one of Kosovo's premier destinations for relaxation and luxury. Nestled in the stunning landscapes of Brezovica, our hotel combines modern elegance with unparalleled service, offering guests an unforgettable experience. With world-class interiors, a luxurious spa, and a commitment to excellence in hospitality, Brezovica Hotel & Spa reflects our company's dedication to quality in everything we do.",
			consolidationTitle:
				"Our comprehensive range of services includes concrete production, material transportation, gravel and aggregate production, ceramics supply, and asphalt production and application. We pride ourselves on seamlessly managing every aspect of the construction process, ensuring efficiency, precision, and excellence.",
			textTitle:
				"What sets Dino Company apart is our unwavering commitment to quality and relentless pursuit of innovation. For over two decades, we have built strong and lasting relationships with our clients by consistently delivering outstanding results.",
			partnerTitle:
				"Partnering with Dino Company means choosing reliability, expertise, and a commitment to bringing your vision to life. With us, you can be confident in achieving extraordinary success in every project.",
			additionalDetails:
				"Dino Company also owns Brezovica Hotel & Spa, a luxury destination in the breathtaking landscapes of Brezovica. The hotel combines modern elegance with world-class service, offering an unforgettable experience for visitors.",
		},
		servicesTranslations: {
			header: "Services and Offerings",
			card1Title: "Construction and Project Management",
			card1Description:
				"We offer comprehensive management services to guide construction projects from concept to completion.",
			card2Title: "Ceramics and Stone Grinding",
			card2Description:
				"We offer a diverse range of ceramic products and services designed to enhance the quality of construction materials.",
			card3Title: "Renewable Energy Supplies",
			card3Description:
				"We specialize in innovative technologies and materials to enhance energy efficiency and reduce costs.",
		},

		newsTranslationsButton: {
			articleNotFound: "Article not found.",
			backToNews: "Back to News",
		},
	},
	sq: {
		header: {
			home: "Ballina",
			aboutUs: "Rreth Nesh",
			units: "Njësite",
			construction: "Dino Ndërtimi",
			ceramic: "Dino Qeramika",
			gurethyes: "Dino Gurëthyes",
			beton: "Dino Beton",
			asfalt: "Dino Asfalt",
			news: "Lajme",
			contact: "Kontakti",
		},
		carousel: {
			welcome: "Eksperte ne Zgjidhje, Partnere ne Sukses",
			title: "DINO COMPANY",
		},
		njesite: {
			dinoQeramika: "Dino Qeramika",
			dinoNdertime: "Dino Ndërtimi",
			dinoBeton: "Dino Beton",
			dinoGurethyes: "Dino Gurëthyes",
			dinoAsfalt: "Dino Asfalt",
		},
		cards: {
			dinoBeton:
				"DINO BETON ofron prodhimin e betonit të gatshëm me cilësi të lartë, duke përmbushur standardet më të larta. Me përzierje të balancuar dhe teknologji moderne, betoni ynë është ideal për çdo lloj ndërtimi, duke garantuar forcë dhe jetëgjatësi të projekteve tuaja.",

			dinoAsfalt:
				"DINO ASFALT prodhon asfalt me cilësi të lartë, të përshtatshëm për ndërtimin dhe mirëmbajtjen e rrugëve dhe infrastrukturës. Asfalti ynë është projektuar për të ofruar qëndrueshmëri dhe rezistencë ndaj kushteve të vështira klimatike.",

			dinoNdertime:
				"DINO NDËRTIMI ofron shërbime të plota ndërtimi, që nga konceptimi arkitektonik deri te realizimi final. Me një ekip profesionistësh me përvojë, ne ofrojmë projekte të përfunduara me standardet më të larta dhe siguri teknike.",

			dinoQeramika:
				"DINO QERAMIKA ofron një gamë të gjerë qeramikash cilësore, të përshtatshme për hapësira të brendshme dhe të jashtme. Qeramikat tona përmbajnë dizajne moderne dhe janë të ndërtuara për të qëndruar në kohë.",

			dinoGurethyes:
				"DINO GURËTHYES prodhon gurë ndërtimi cilësor për beton dhe projekte të mëdha inxhinierike. Produktet tona ofrojnë një bazë të fortë dhe të qëndrueshme për ndërtimet tuaja.",
		},

		whyUsTranslations: {
			whoAreWeTitle: "Kush Jemi Ne?",
			whoAreWeText1:
				"Dino Company është një lider ndërtimi, i specializuar në komplekse tregtare dhe banesore. Me një fokus në inovacion dhe cilësi, kemi ndërtuar një reputacion për projekte të cilësisë së lartë që përmbushin nevojat e klientëve tanë.",

			whyChooseUsTitle: "Pse të zgjidhni Dino Company?",
			experienceTitle: "Përvoja dhe Profesionalizmi",
			experienceText:
				"Me dekada përvojë, Dino Company ka drejtuar projekte të mëdha, nga qendrat tregtare deri te zhvillimet banesore. Ekipi ynë ekspert siguron që çdo projekt të realizohet me kujdes dhe saktësi.",

			qualityProductsTitle: "Produkte dhe Shërbime me Cilësi të Lartë",
			qualityProductsText:
				"Ofrimi i materialeve ndërtimore cilësore si beton, asfalt dhe gur, të dizajnuara për qëndrueshmëri. Ne ofrojmë shërbime të plota ndërtimi që nga planifikimi deri te realizimi përfundimtar.",

			clientCommitmentTitle: "Angazhimi ndaj Klientëve",
			clientCommitmentText:
				"Klientët tanë janë prioriteti ynë. Ne ofrojmë zgjidhje të personalizuara, duke siguruar përfundimin e projekteve sipas kërkesave, në kohë dhe brenda buxhetit.",
		},
		newsTranslations: {
			header: "Lajmet E Fundit",
			readMore: "Më shumë",
		},

		news: [
			{
				title: "Transformimi i Rrugës së Unazës në Prishtinë:",
				content:
					"Punimet në Rrugën e Unazës në Prishtinë kanë filluar të marrin një formë krejtësisht tjetër falë kontributit të Dino Company. Me angazhim të thellë dhe ekspertizë të konsoliduar në fushën e ndërtimit, kjo kompani ka luajtur një rol kyç në transformimin e projektit.",
				image: "/assets/67.jpg",
			},
			{
				title: "Salla Sportive në Lipjan:",
				content:
					"Salla sportive në Lipjan, e përfunduar disa muaj më parë, ofron një ambient të përshtatshëm për aktivitete të ndryshme sportive. Me ardhjen e pranverës, qytetarët e Lipjanit kanë mundësinë të përfitojnë nga infrastruktura e kësaj salle për të ushtruar dhe promovuar stërvitje të shëndetshme.",
				image: "/assets/22.jpg",
			},
			{
				title: "Projekti i Rrugës Prishtinë-Gjilan:",
				content:
					"Pas punës së palodhur dhe përpjekjeve të vazhdueshme, rruga Prishtinë-Gjilan është pothuajse përfunduar. Ky projekt, një nga arteriet më të rëndësishme për lidhjen rajonale, ka kaluar nëpër disa faza komplekse ndërtimi, ku angazhimi dhe profesionalizmi kanë qenë thelbësore për suksesin e tij.",
				image: "/assets/44.jpg",
			},
			{
				title: "Hotel & SPA në Brezovicë:",
				content:
					"Mes një sërë projektesh të realizuara nga Dino Qeramika, Hoteli & SPA në Brezovicë dallohet si një shembull të jashtëzakonshëm të angazhimit për çdo detaj. Ky projekt është i veçantë jo vetëm për dizajnin e tij estetik tërheqës, por gjithashtu për përqendrim maksimal në cilësi, duke reflektuar standardet më të larta në çdo aspekt të tij.",
				image: "/assets/hoteli.11.jpeg",
			},
			{
				title: "Dino Residence",
				content:
					"Jemi të lumtur të njoftojmë nisjen e ndërtimit të një objekti të ri dhe modern në Majën e Vetërnikut, një nga zonat më të bukura dhe më të kërkuara të Prishtinës. Ky projekt ofron kushte të shkëlqyera jetese, me pamje të mrekullueshme dhe një lokacion të përshtatshëm, pranë shërbimeve dhe infrastrukturës kryesore të qytetit.",
				image: "/assets/veternik.jpeg",
			},
		],
		contact: {
			title: "Vizitoni Lokacionin Tonë",
			subtitle: "Ekspertë në Zgjidhje, Partnerë në Sukses",
			addressHeading: "Adresa Jonë",
			address: "14000, Mgj. Prishtinë - Ferizaj",
			workingHours: {
				title: "Orari i Punes",
				mondayToFriday: "Të Hënën - Premten: 8am - 6pm",
				saturday: "Të Shtunen : 9am - 5pm",
				sunday: "Të Dielen : Mbyllur",
			},
			contact: {
				title: "Kontakti",
				email: "Email: info@dino-company.com",
				phone: "Telefoni: 038 566 666",
			},
		},
		aboutTranslations: {
			header: "Rreth Nesh",
			subHeader: "Zbuloni kush jemi, çfarë na nxit, dhe si bëjmë ndryshimin.",
			historyTitle: "Historia Jonë",
			historyText:
				"Ne jemi të përkushtuar ndaj ekselencës për mbi 20 vjet, duke krijuar ndryshime dhe inovacione të rëndësishme.",
			missionTitle: "Misioni Ynë",
			missionText:
				"Misioni ynë është të ofrojmë shërbime dhe produkte të cilësisë së lartë që adresojnë nevojat e klientëve me profesionalizëm dhe ekspertizë.",
			visionTitle: "Vizioni Ynë",
			visionText:
				"Vizioni ynë është të udhëheqim me përgjegjshmëri sociale dhe të vendosim standarde të reja ekselence.",
			textTitle: "Ajo që Na Veçon",
			textBody:
				"Ajo që e veçon Dino Company është përkushtimi ynë i palëkundur ndaj cilësisë dhe ndjekja jonë e pamëshirshme për inovacion.",
			partnerTitle: "Pse të Zgjidhni Dino Company",
			partnerText:
				"Partneriteti me Dino Company do të thotë të zgjedhësh besueshmërinë, ekspertizën dhe angazhimin për të sjellë në jetë vizionin tuaj.",
			additionalDetailsTitle: "Detaje Shtesë",
			additionalDetailsText:
				"Dino Company gjithashtu zotëron Brezovica Hotel & Spa, një destinacion luksi në peizazhin mahnitës të Brezovicës.",
			teamTitle: "Takoni Ekipin Tonë",
		},
	},
	historyTranslations: {
		title: "Historia dhe Evolucioni",
		description:
			"E themeluar në vitin 2000, Kompania DINO filloi si një biznes individual me një vizion të qartë: të vendosë standarde të reja në industrinë e ndërtimit duke ofruar cilësi të jashtëzakonshme, zgjidhje inovative dhe përkushtim të pashembullt për klientët tanë. Me kalimin e viteve, ne jemi rritur në një emër të besuar në ndërtim dhe furnizim me materiale premium, duke fituar një reputacion si lider në këtë fushë.",
		foundingTitle: "Ne operojmë përmes pesë degëve të specializuara:",
		foundingPoints: [
			{
				label: "DINO QERAMIKA:",
				text: "Furnizimi i qeramikës më të mirë, duke përfshirë importet ekskluzive nga prodhuesit më të njohur të Italisë.",
			},
			{
				label: "DINO NDËRTIMI:",
				text: "Ofrimi i zgjidhjeve të ndërtimit me çelës në dorë që plotësojnë dhe tejkalojnë standardet e industrisë.",
			},
			{
				label: "DINO GURTHYESI:",
				text: "Ekspertë në prodhimin e zhavorrit dhe agregatit për të gjitha kërkesat e ndërtimit.",
			},
			{
				label: "DINO BETONI:",
				text: " Prodhimi i betonit me cilësi të lartë të përshtatur me specifikimet e projektit, me transport të besueshëm në vendin tuaj.",
			},
			{
				label: "DINO ASFALTI:",
				text: " Udhëheqës në prodhimin dhe aplikimin e saktë të asfaltit për rrugë dhe infrastrukturë.",
			},
		],
		expansionTitle:
			"Përveç ekspertizës sonë të gjerë të ndërtimit, ne jemi krenarë që zotërojmë Brezovica Hotel & Spa, një nga destinacionet kryesore të Kosovës për relaksim dhe luks. I vendosur në peizazhin befasues të Brezovicës, hoteli ynë ndërthur elegancën moderne me shërbimin e pashembullt, duke u ofruar mysafirëve një eksperiencë të paharrueshme. Me interier të klasit botëror, një spa luksoze dhe një përkushtim për përsosmërinë e mikpritjes, Brezovica Hotel & Spa pasqyron përkushtimin e kompanisë sonë për cilësinë në gjithçka që bëjmë.",

		consolidationTitle:
			"Gama jonë e plotë e shërbimeve përfshin prodhimin e betonit, transportin e materialeve, prodhimin e zhavorrit dhe agregatit, furnizimin me qeramikë dhe prodhimin dhe aplikimin e asfaltit. Ne krenohemi që menaxhojmë pa probleme çdo aspekt të procesit të ndërtimit, duke siguruar efikasitet, saktësi dhe përsosmëri.",
		textTitle:
			"Ajo që e veçon DINO Company është përkushtimi ynë i palëkundur ndaj cilësisë dhe ndjekja jonë e pamëshirshme për inovacion. Për më shumë se dy dekada, ne kemi ndërtuar marrëdhënie të forta dhe të qëndrueshme me klientët tanë duke ofruar vazhdimisht rezultate të jashtëzakonshme.",
		partnerTitle:
			"Partneriteti me DINO Company do të thotë të zgjedhësh besueshmërinë, ekspertizën dhe angazhimin për të sjellë në jetë vizionin tënd. Me ne, ju mund të jeni të sigurt në arritjen e suksesit të jashtëzakonshëm në çdo projekt.",
		additionalDetails:
			"Dino Company gjithashtu zotëron Brezovica Hotel & Spa, një destinacion luksi në peizazhin mahnitës të Brezovicës. Hoteli kombinon elegancën moderne me shërbitje të klasit botër, duke ofruar një eksperiencë të paharrueshme për vizitorët.",
	},
	servicesTranslations: {
		header: "Shërbimet dhe Ofertat",
		card1Title: "Menaxhimi i Ndërtimit dhe Projektit",
		card1Description:
			"Ne ofrojmë shërbime gjithëpërfshirëse të menaxhimit për të udhëhequr projektet e ndërtimit nga koncepti deri në përfundim.",
		card2Title: "Keramika dhe Bluarja e Gurit",
		card2Description:
			"Ne ofrojmë një gamë të gjerë produktesh dhe shërbimesh qeramike për të përmirësuar cilësinë e materialeve të ndërtimit.",
		card3Title: "Furnizime me Energji",
		card3Description:
			"Ne specializohemi në teknologji inovative dhe materiale për të përmirësuar efikasitetin e energjisë dhe për të ulur kostot.",
	},
	newsTranslationsButton: {
		articleNotFound: "Artikulli nuk u gjet.",
		backToNews: "Kthehu te Lajmet",
	},
};

export default Translations;
