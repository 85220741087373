import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ContactUs from "../components/ContactUs";
import Marquee from "../components/Marquee";
import AOS from "aos";
import "aos/dist/aos.css";

const DinoBeton = () => {
  const language = useSelector((state) => state.language);
  const activeLanguage = language.language || "en";
  const translations = language.translations || {};

  const dinoBetonTranslations = translations[activeLanguage]?.dinoBetonTranslations || {
    en: {
      header: "DINO CONCRETE",
      subHeader: "With experience and tradition in the construction industry.",
      section1Title: "DINO CONCRETE",
      section1Description:
        "DINO Concrete is a company with experience and tradition in the construction industry, with two production units strategically located in Pristina and Lipjan. The company specializes in the production of all classes of concrete according to the highest international standards. The total production capacity reaches up to 300 m³ of concrete per hour, with the Pristina plant contributing 120 m³ and the Lipjan plant contributing 180 m³.",
      section2Title: "Technology and Dedication",
      section2Description:
        "DINO Concrete is equipped with modern technology and infrastructure that ensures the production of quality and durable concrete, meeting all technical and legal requirements for the most sophisticated constructions. It operates in full compliance with the Environmental Protection Law, ensuring a careful balance between industrial development and environmental preservation.",
      section2Description2:
        "With the necessary licenses and a trained and qualified staff, the company has built a strong reputation for reliability and quality in the concrete market.",
      section3Title: "Our Goal",
      section3Description:
        "At its core, DINO Concrete aims to be the first-choice partner for the largest construction projects in Kosovo and beyond, offering innovative and customized solutions for every project."
    },
    sq: {
      header: "DINO BETON",
      subHeader: "Me përvojë dhe traditë në industrinë e ndërtimit.",
      section1Title: "DINO BETON",
      section1Description:
        "DINO Beton është një kompani me përvojë dhe traditë në industrinë e ndërtimit, me dy njësi prodhimi strategjikisht të vendosura në Prishtinë dhe Lipjan. Kompania është e specializuar në prodhimin e të gjitha klasave të betonit sipas standardeve më të larta ndërkombëtare. Kapaciteti total i prodhimit arrin deri në 300 m³ beton në orë, ku fabrika në Prishtinë kontribuon me 120 m³ dhe ajo në Lipjan me 180 m³.",
      section2Title: "Teknologjia dhe Përkushtimi",
      section2Description:
        "DINO Beton është e pajisur me teknologji moderne dhe infrastrukturë që garanton prodhimin e betonit cilësor dhe të qëndrueshëm, duke përmbushur të gjitha kërkesat teknike dhe ligjore për ndërtimet më të sofistikuara. Ajo operon në përputhje të plotë me Ligjin për Mbrojtjen e Ambientit, duke siguruar një balancë të kujdesshme midis zhvillimit industrial dhe ruajtjes së mjedisit.",
      section2Description2:
        "Me licencat e nevojshme dhe me një staf të trajnuar dhe të kualifikuar, kompania ka krijuar një reputacion të fortë për besueshmëri dhe cilësi në tregun e betonit.",
      section3Title: "Synimi ynë",
      section3Description:
        "Në thelb, DINO Beton synon të jetë partneri i parë i zgjedhjes për projektet më të mëdha ndërtimore në Kosovë dhe më gjerë, duke ofruar zgjidhje inovative dhe të personalizuara për çdo projekt."
    }
  };

  const activeTranslations = dinoBetonTranslations[activeLanguage];

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: "ease-in-out",
      once: true, 
    });
  }, []);

  return (
    <div className="container mx-auto px-6 py-12">
      <div className="relative mb-12 bg-gray-900 rounded-lg overflow-hidden shadow-2xl" data-aos="fade-right">
        <div
          className="absolute inset-0 bg-cover bg-bottom"
          style={{ backgroundImage: "url('assets/15.jpg')" }}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-red-900 to-transparent"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-64 text-center p-6">
          <h1 className="text-5xl md:text-6xl font-extrabold text-white mb-4 leading-tight">
            {activeTranslations.header}
          </h1>
          <p className="text-lg md:text-xl text-gray-200 max-w-4xl mx-auto">
            {activeTranslations.subHeader}
          </p>
        </div>
      </div>

      <section className="space-y-16">
  <div className="relative grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8">
    <div className="relative" data-aos="fade-right">
      <img
        src="assets/18.jpg"
        alt="DINO Concrete"
        className="w-full sm:h-[300px] md:h-[350px] lg:h-auto object-cover object-center"
        />
    </div>

    <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col justify-center items-center" data-aos="fade-left">
      <h2 className="text-3xl font-semibold text-red-800 mb-4 text-center">
        {activeTranslations.section1Title}
      </h2>
      <p className="text-lg leading-relaxed text-gray-700 text-center">
        {activeTranslations.section1Description}
      </p>
    </div>
  </div>

  <section className="mt-12">
    <div className="md:w-full bg-white p-8 rounded-lg shadow-2xl transform hover:translate-y-2 transition-transform duration-300 ease-in-out" data-aos="fade-left">
      <h2 className="text-3xl md:text-4xl font-extrabold text-red-700 mb-6">
        {activeTranslations.section2Title}
      </h2>
      <p className="text-lg leading-relaxed mb-6 text-gray-700">
        {activeTranslations.section2Description}
      </p>
      <p className="text-lg leading-relaxed mb-6 text-gray-700">
        {activeTranslations.section2Description2}
      </p>
    </div>
  </section>

  <section className="mt-12">
    <div className="md:w-full bg-white p-8 rounded-lg shadow-2xl transform hover:translate-y-2 transition-transform duration-300 ease-in-out" data-aos="zoom-in-up">
      <h2 className="text-3xl md:text-4xl font-extrabold text-red-700 mb-6">
        {activeTranslations.section3Title}
      </h2>
      <p className="text-lg leading-relaxed mb-6 text-gray-700">
        {activeTranslations.section3Description}
      </p>
    </div>
  </section>
</section>

      <section data-aos="fade-up">
        <ContactUs />
      </section>

      <section data-aos="fade-up">
        <Marquee />
      </section>
    </div>
  );
};

export default DinoBeton;
