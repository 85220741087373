import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import TeamMembers from "../layout/TeamMembers";
import Marquee from "../components/Marquee";
import HistorySection from "../components/HistorySection";
import SercivesSection from "../components/ServicesSection";
import ContactUs from "../components/ContactUs";
import AOS from "aos";
import "aos/dist/aos.css";

function About() {
  const language = useSelector((state) => state.language);
  const activeLanguage = language.language || "en"; // Default to English if no language is set
  const translations = language.translations || {};

  const aboutTranslations = translations[activeLanguage]?.aboutTranslations || {};

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="container mx-auto px-6 py-12">
      {/* Hero Section */}
      <div className="relative mb-12 bg-gray-800 rounded-lg overflow-hidden shadow-lg">
        <div
          className="absolute inset-0 bg-cover bg-center lg:bg-fixed"
          style={{ backgroundImage: "url('assets/20-NENTORI-part-1.jpg')" }}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-red-900 to-transparent"></div>
        <div
          className="relative z-10 flex flex-col items-center justify-center h-48 md:h-56 lg:h-64 text-center p-6"
          data-aos="fade-up"
        >
          <h1 className="text-5xl md:text-6xl font-bold text-white mb-4 leading-tight">
            {aboutTranslations.header || "RRETH NESH"}
          </h1>
          <p className="text-lg md:text-xl text-white max-w-4xl mx-auto">
            {aboutTranslations.subHeader ||
              "Zbuloni kush jemi, çfarë na nxit, dhe si bëjmë ndryshimin."}
          </p>
        </div>
      </div>

      {/* History Section */}
      <section className="mb-12">
        <h2
          className="text-4xl md:text-5xl font-bold text-red-800 text-center mb-8"
          data-aos="fade-right"
        >
          {aboutTranslations.historyTitle || "Historia Jonë"}
        </h2>
        <div data-aos="fade-left">
          <HistorySection />
        </div>
      </section>

      {/* Services Section */}
      <section>
        <SercivesSection />
      </section>

      {/* Mission & Vision Section */}
      <section
        className="mb-12 flex flex-col gap-4 lg:flex-row lg:space-x-8"
        data-aos="fade-right"
      >
        <div className="bg-white p-8 rounded-lg shadow-xl border-t-4 border-gray-800 mx-auto lg:w-1/2 mb-8 lg:mb-0">
          <h2 className="text-3xl md:text-4xl font-bold text-red-800 mb-6">
            {aboutTranslations.missionTitle || "Misioni ynë"}
          </h2>
          <p className="text-lg leading-relaxed">
            {aboutTranslations.missionText ||
              "Misioni ynë është të ofrojmë shërbime dhe produkte të cilësisë së lartë që adresojnë nevojat e klientëve me profesionalizëm dhe ekspertizë."}
          </p>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-xl border-t-4 border-gray-800 mx-auto lg:w-1/2">
          <h2 className="text-3xl md:text-4xl font-bold text-red-800 mb-6">
            {aboutTranslations.visionTitle || "Vizioni ynë"}
          </h2>
          <p className="text-lg leading-relaxed">
            {aboutTranslations.visionText ||
              "Vizioni ynë është të udhëheqim me përgjegjshmëri sociale dhe të vendosim standarde të reja ekselence."}
          </p>
        </div>
      </section>

      {/* "Ajo që Na Veçon" Section */}
      <section className="mb-12 mt-48">
        <h2
          className="text-4xl md:text-4xl font-bold text-red-800 text-center mb-8"
          data-aos="fade-right"
        >
          {aboutTranslations.textTitle || "Ajo që Na Veçon"}
        </h2>
        <p className="text-lg text-gray-600 leading-relaxed text-center max-w-4xl mx-auto">
          {aboutTranslations.textBody ||
            "Ajo që e veçon Dino Company është përkushtimi ynë i palëkundur ndaj cilësisë dhe ndjekja jonë e pamëshirshme për inovacion."}
        </p>
      </section>

      {/* Why Choose Dino Company */}
      <section className="mb-12">
        <h2
          className="text-4xl md:text-4xl font-bold text-red-800 text-center mb-8"
          data-aos="fade-right"
        >
          {aboutTranslations.partnerTitle || "Pse të Zgjidhni Dino Company"}
        </h2>
        <p className="text-lg text-gray-600 leading-relaxed text-center max-w-4xl mx-auto">
          {aboutTranslations.partnerText ||
            "Partneriteti me Dino Company do të thotë të zgjedhësh besueshmërinë, ekspertizën dhe angazhimin për të sjellë në jetë vizionin tuaj."}
        </p>
      </section>

      {/* Additional Details Section */}
      <section className="mb-48">
        <h2
          className="text-4xl md:text-4xl font-bold text-red-800 text-center mb-8"
          data-aos="fade-right"
        >
          {aboutTranslations.additionalDetailsTitle || "Detaje Shtesë"}
        </h2>
        <p className="text-lg text-gray-600 leading-relaxed text-center max-w-4xl mx-auto">
          {aboutTranslations.additionalDetailsText ||
            "Dino Company gjithashtu zotëron Brezovica Hotel & Spa, një destinacion luksi në peizazhin mahnitës të Brezovicës."}
        </p>
      </section>

      {/* Meet the Team Section */}
      <section className="mb-12">
        <h2
          className="text-4xl md:text-4xl font-bold text-red-800 text-center mb-8"
          data-aos="fade-right"
        >
          {aboutTranslations.teamTitle || "Takoni Ekipin Tonë"}
        </h2>
        <TeamMembers />
      </section>

      {/* Contact Us Section */}
      <section data-aos="fade-right">
        <ContactUs />
      </section>

      {/* Marquee Section */}
      <section data-aos="fade-right">
        <div>
          <Marquee />
        </div>
      </section>
    </div>
  );
}

export default About;
