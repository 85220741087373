import React from "react";

const MarqueeItemSmall = () => {
  const images = [
    "/assets/Mirage.png",
    "/assets/lineart.png",
    "/assets/seramiksan.png",
    "/assets/azuvi.png",
    "/assets/cerrad.png",
    "/assets/eco.png",
    "/assets/etili.png",
    "/assets/kerakoll.png",
    "/assets/mossaica-logo.png"
  ];

  return (
    <div className="relative overflow-hidden w-full">
      <div className="marquee-container">
        <div className="marquee">
          {[...images, ...images].map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Marquee item ${index}`}
              className="marquee-image"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarqueeItemSmall;
