import React from "react";
import { useSelector } from "react-redux";
import Translations from "../redux/language/Translations";

const ContactUs = () => {
  const language = useSelector((state) => state.language);
  const currentLanguage = language.language || "en";

  const { title, subtitle, addressHeading, address, workingHours, contact } =
    Translations[currentLanguage].contact;

  return (
    <section className="mt-14">
      <div className="max-w-7xl mx-auto border-t-2 border-t-red-800 py-20 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-red-800">{title}</h2>
          <p className="mt-4 text-lg text-gray-500">{subtitle}</p>
        </div>

        <div className="mt-16 lg:mt-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div
              className="rounded-lg overflow-hidden p-6 shadow-2xl relative"
              style={{
                boxShadow:
                  "0 10px 20px rgba(0, 0, 0, 0.3), 0 6px 6px rgba(0, 0, 0, 0.2)",
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6009.155131560911!2d21.145420421599546!3d42.54408537240565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135483b3a1ef3a95%3A0x94c4fd8ba6709791!2sDino%20Qeramika!5e0!3m2!1sen!2s!4v1741606521415!5m2!1sen!2s"
                width="100%" // Adjusted to fill the container
                height="450"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Dino Qeramika Location"
              ></iframe>
            </div>

            <div>
              <div className="max-w-full mx-auto rounded-lg overflow-hidden">
                <div className="px-6 py-4">
                  <h3 className="text-lg font-medium text-red-800">
                    {addressHeading}
                  </h3>
                  <p className="mt-1 text-gray-500">
                    <a
                      href="https://www.google.com/maps/search/?q=Dino+Qeramika"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-500 hover:text-red-800 decoration-none hover:underline"
                    >
                      {address}
                    </a>
                  </p>
                </div>
                <div className="border-t border-red-800 px-6 py-4">
                  <h3 className="text-lg font-medium text-red-800">
                    {workingHours.title}
                  </h3>
                  <p className="mt-1 text-gray-500">
                    {workingHours.mondayToFriday}
                  </p>
                  <p className="mt-1 text-gray-500">{workingHours.saturday}</p>
                  <p className="mt-1 text-gray-500">{workingHours.sunday}</p>
                </div>
                <div className="border-t border-red-800 px-6 py-4">
                  <h3 className="text-lg font-medium text-red-800">
                    {contact.title}
                  </h3>
                  <p className="mt-1 text-gray-500">{contact.email}</p>
                  <p className="mt-1 text-gray-500">+383 44 59 59 59</p>
                  <p className="mt-1 text-gray-500">+383 48 497 777</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
